import { Props } from "./types";

import Pannellum from "../Pannellum";
import { useEffect, useState } from "react";
import Spinner from "../LoadingSpinner";

export const PanoramicViewTpl = ({ imageUrl, pitch, yaw }: Props) => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (imageUrl === "") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [imageUrl]);

  return (
    isLoading ?       
      <div className="w-auto h-auto mx-auto flex max-h-full max-w-full flex-grow">
        <Spinner className="w-full max-h-full" size={50} />
      </div>
      : 
      <Pannellum
        image={imageUrl}
        pitch={pitch}
        yaw={yaw}
        hfov={100}
        autoLoad
        showZoomCtrl={false}
        mouseZoom={false}
        minHfov={100}
        maxHfov={100}
      />
  );
};
