import { useEffect, useMemo, useState } from "react";
import useRenderConfig from "../../utils/useRenderConfig";
import useRenderOnDemand from "../../utils/useRenderOnDemand";
import { ImageFormat, ImageRequest, ImageStatus, ImageType } from "../../utils/useRenderOnDemand.types";
import TurntableTpl from "./TurntableTpl";
import DataContainer from "../../services/DataContainer";
import { MarketSettings, RodSettings } from "../../enum/enums";
import { useParams } from "react-router-dom";
import Spinner from "../LoadingSpinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const Turntable = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageStatus, setImageStatus] = useState<any>("");
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false);

  const {modelName} = useParams();
  const {modelNameFromUrl} = useParams();
  const steeringwheel_position = DataContainer.sectionKey(MarketSettings.MARKET_SETTINGS, MarketSettings.STEERINGWHEEL_POSITION);  
    
  const renderConfig = useRenderConfig();

  const renderSpec: ImageRequest = {
    sequence: "360",
    attributes: { 
      stage: "outdoor",
      steeringwheel_position: steeringwheel_position,
    },
    renderVersion: parseInt(DataContainer.sectionKey(RodSettings.ROD_SETTINGS, `${modelName ? modelName : modelNameFromUrl}_renderversion`)),
    configuration: renderConfig,
    format: ImageFormat.JPEG,
    width: 2500,
    height: 1875,
    type: ImageType.Image,
    compressionQuality: 100,
  };
  
  
  const response = useRenderOnDemand(renderSpec);

  useEffect(() => {
    const numReady = response.urlAssetsStatus.filter((x) => x).length;
    const totalImages = Math.max(1, response.urlAssetsStatus.length);

    if (response.status === ImageStatus.Queued) { // && numReady >= totalImages
      if (imageStatus !== ImageStatus.Ready) {
        setImageStatus(ImageStatus.Ready);
      }
    } else if (response.status !== imageStatus) {
      setImageStatus(response.status);
    }

    if (imageStatus === ImageStatus.None) {
      setIsLoading(true);
    } else if (response.status === ImageStatus.Ready) {
      setIsLoading(false);
    } else if (imageStatus === ImageStatus.Timeout) {
      setHasTimedOut(true);
    }
  }, [response, imageStatus]);

  // const urls = [];
  // for (let i = 1; i <= 301; i++) {
  //   const url = `https://dq5knjx223dd1.cloudfront.net/clients/u-9/a-12/rod-cache/4233154da985532936fb35738370c04c/assetImage-${i}.jpeg`;
  //   urls.push(url);
  // }


  useEffect(() => {
    return () => setImageStatus(null);
  }, []);

  return (
    (hasTimedOut || response.error !== "") ? 
      <div className="w-full h-full mx-auto flex max-h-full max-w-full flex-grow">
        <ErrorMessage /> 
      </div>
      : isLoading ? (
        <div className="w-full h-full mx-auto flex max-h-full max-w-full flex-grow">
          <Spinner className="w-full max-h-full" size={50} />
        </div>
      ) : (
        <TurntableTpl
          images={response.urlAssets}
          duration={0.6}
        />)
  );
};

export default Turntable;