import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../Button/Button";
import { MainButtons, RodSettings } from "../../enum/enums";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { Image } from "../images";
import Spinner from "../LoadingSpinner";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import classNames from "classnames";
import DataContainer from "../../services/DataContainer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { kindOfIconButton } from "../../consts/Button/iconConstButton";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { colorButton } from "../../enum/Button/colorButton.model";

interface SliderProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginate: (direction: number) => void;
  hash: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  currentSource: string;
  exteriorViewPoints: string[];
  onSummary?: boolean;
  showError?: boolean;
  rodResponseError?: string;
}

const SliderTpl = ({
  page,
  paginate,
  hash,
  isLoading,
  currentSource,
  setPage,
  exteriorViewPoints,
  onSummary,
  showError,
  rodResponseError
}: SliderProps) => {

  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const { activeComponentGroups } = useContext(DataContainerContext);
  const currentComponentGroup = activeComponentGroups.name;

  const onMouseDown = (e: any) => {
    if (sliderRef.current && sliderRef.current.contains(e.target)) {
      setIsDragging(true);
      setInitialX(e.clientX);
    } else {
      setIsDragging(false);
    }
  };
  const onMouseMove = (e: any) => {
    e.preventDefault(); // Prevent default mouse move behavior
    if (sliderRef.current && sliderRef.current.contains(e.target)) {
      if (!isDragging) return;

      const sliderWidth = sliderRef.current?.offsetWidth || 0;
      const currentX = e.clientX;
      const dragDistance = currentX - initialX;

      if (dragDistance <= -sliderWidth / 2) {
        paginate(1);
        setIsDragging(false);
      } else if (dragDistance >= sliderWidth / 2) {
        paginate(-1);
        setIsDragging(false);
      }
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const activePaginationClass =
    "h-2.5 w-2.5 bg-dark-grey rounded-full mx-1 border border-dark-grey";
  const defaultPaginationClass =
    "rounded-full mx-2 bg-white border border-dark-grey h-2 w-2";

  return (
    <div
      className={classNames("overflow-hidden w-full h-full flex flex-col", {
        absolute: currentComponentGroup === "Interior" && !onSummary,
      })}
    >
      <div
        className={classNames(
          "flex transition-transform duration-300 ease-in w-full flex-grow items-center",
        )}
        ref={sliderRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onPointerMove={onMouseMove}
        onPointerDown={onMouseDown}
        onPointerUp={onMouseUp}
      >
        <div
          className={classNames(
            "flex w-full aspect-video items-center justify-center transition-transform duration-300 ease-in",
            {
              "h-auto absolute":
                currentComponentGroup === "Interior" && !onSummary,
              "max-h-[70vh]":
                currentComponentGroup !== "Interior" || onSummary,
              "h-[70vh]": onSummary,
            }
          )}
        >
          <Button 
            customClassNames={classNames(
              "absolute top-1/2 z-20 h-10 w-10 left-6 bg-light-grey-light hover:bg-light-grey-hover", 
              {
                "invisible mx-2": page === 0,
                "hidden m-0": currentComponentGroup === "Interior" && !onSummary,
              }
            )}
            onClickEvent={() => paginate(-1)}
            variant={VarianButtonEnum.ICONBUTTON}
            icon={IconModelEnum.ARROWLEFT}
            // bgColor={colorButton.TRANSPARENT}
          />

          {(showError || rodResponseError) ? <ErrorMessage /> : isLoading ? (
            <div className="w-auto h-auto mx-auto flex max-h-full max-w-full flex-grow">
              <Spinner className="w-full max-h-full" size={50} />
            </div>
          ) : (
            <Image 
              //   draggable={false}
              className={classNames(
                "w-full right-0 left-0 top-0 bottom-0 absolute h-full z-10 object-cover max-h-full max-w-full flex-1 object-center",
                { "w-screen h-full object-cover": currentComponentGroup === "Interior" && !onSummary }
              )}
              src={currentSource}
              alt={`Slide ${page + 1}`}
            />
          )}

          <Button 
            customClassNames={classNames(
              "absolute top-1/2 right-6 z-20 h-10 w-10 bg-light-grey-light hover:bg-light-grey-hover", 
              {
                "invisible mx-2": exteriorViewPoints && page === exteriorViewPoints.length - 1,
                "hidden m-0": currentComponentGroup === "Interior" && !onSummary,
              }
            )}
            onClickEvent={() => paginate(1)}
            variant={VarianButtonEnum.ICONBUTTON}
            icon={IconModelEnum.ARROWRIGHT}
            // bgColor={colorButton.TRANSPARENT}
          />
        </div>
      </div>
      <div
        className={classNames(
          "flex justify-center relative items-center w-full h-12 bottom-2 z-20",
          { hidden: currentComponentGroup === "Interior" && !onSummary }
        )}
      >
        {exteriorViewPoints &&
          (currentComponentGroup !== "Interior" || onSummary) &&
          exteriorViewPoints.map((e, index) => {
            return (
              <div
                className="flex items-center justify-center w-6"
                key={index}
              >
                <button
                  onClick={() => setPage(index)}
                  className={
                    index === page
                      ? activePaginationClass
                      : defaultPaginationClass
                  }
                ></button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SliderTpl;
