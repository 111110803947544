import { List } from "../../components/List/List";
import { listCatType, Props } from "./types";
import { Logo } from "../../components/Logo/Logo";
import { Typography } from "../../components/typography";
import { DisclaimerSection } from "../../components/Disclaimer-Section/DisclaimerSection";
import {useLocation, useNavigate} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import DataContainer from "../../services/DataContainer";
import { Loader } from "../../components/Loader/Loader";
import { MainSettings, DisclaimerTexts } from "../../enum/enums";

export const Segment = ({ title }: Props) => {
  const [listCat, setListCat] = useState<listCatType[]>([]);
  const navigate = useNavigate();
  const { data, currentLocale } = useContext(DataContainerContext);
  const { sections }: any = DataContainer.mApplicationData;
  
  const listDisclaimerMenu = [
    {
      title: DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_BUTTON),
      link:  DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_URL)
    },
    {
      title:  DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_BUTTON),
      link:   DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_URL)
    },
  ];

  useEffect(() => {
    setListCat([]);
    Object.keys(DataContainer.mApplicationData).length &&
    sections.forEach((item: any) => {
      if (item.section_name === MainSettings.MAIN_SEGMENTS) {
        const sectionList:listCatType[] = [];
        Object.entries(item.key_values).map((name: any, index: number) => {
          const sectionName = `${MainSettings.SHOW_PRODUCTS}${name[0].replaceAll(" ", "_").replaceAll("&", "and").toLowerCase()}`;
          const items = DataContainer.sectionKey(MainSettings.SEGMENT_SETTINGS, sectionName);
          let productCount = 0;
          if(items !== sectionName){
            productCount = items.split(",").length;
          }

          if(productCount === 0){
            return;
          }

          sectionList.push({
            id: index,
            productCount: productCount,
            title: name[1],
            link: name[0],
            number: index + 1 < 10 ? `0${index + 1}` : `${index}`,
            imageUrl: require(`../../assets/images/${name[0]}.jpeg`),
            icon: "ArrowRightBold",
            onClickEvent: () => "",
            singleItemClassNames: ""
          });
        });
        setListCat(sectionList);
        DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "0", "Application Loaded", "Application Loaded", null);
      }
    });
  }, [data]);

  return (
    <>
      {!data.length ? (
        <Loader/>
      ) : (
        <div className="flex flex-col 2md:flex-row">
          <div className="relative 2md:w-1/2 2md:h-screen pt-0">
            <div className="flex flex-col justify-between h-full pb-5 2md:min-h-[500px]">
              <Logo
                paddingLeftAndRight="p-4 2md:px-10 border-b 2md:border-b-0 border-primary"
                paddingTopAndBottom="2md:pt-14"
              />
              <Typography
                tag="h4"
                className="py-14 text-center text-3xl 2md:text-3xl2 text-black-medium"
              >
                {title}
              </Typography>
              <div className="hidden-vtg 2md:!visible 2md:!flex">
                <DisclaimerSection
                  links={listDisclaimerMenu}
                  positionOfTitle="center"
                  positionOfContent="center"
                  positionOfLinks="center"
                  textTransform={false}
                />
              </div>
            </div>
          </div>
          <div className="2md:w-1/2 h-screen bg-white 2md:bg-dark-grey 2md:overflow-hidden">
            { listCat && <List
              singleItemClassNames="py-12"
              className="mx-3 2md:px-12"
              items={listCat}
              border
              onClickItemEvent={({name, segment}) => {
                localStorage.removeItem("currentSelection");
                DataContainer.mSelectedVariations = [];
                DataContainer.mAttributes = [];
                DataContainer.mSelectionHooks = [];
                DataContainer.logEvent("page_entered", "model", {product: ""});
                navigate(`/${currentLocale.locale}/${name.replaceAll(" ", "_").toLowerCase()}/models`);
                DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "1", "Segment", "Segment Selected", {"Segment": segment?.replaceAll("_", " ").toUpperCase()});
              }}
            />}
            <div className="flex 2md:hidden">
              <DisclaimerSection
                links={listDisclaimerMenu}
                positionOfTitle="center"
                positionOfContent="center"
                positionOfLinks="center"
                textTransform={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
