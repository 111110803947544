import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback
} from "react";
import classnames from "classnames";
import TurntableHandler from "./TurntableHandler";
import style from "./style.module.scss";
import useWindowSize from "../../utils/useWindowSize";

export interface TurntableProps extends React.HTMLAttributes<HTMLDivElement> {
  images: string[];
  time?: number;
  duration?: number;
}

export interface TurntableControls {
  container: React.RefObject<HTMLDivElement | null>;
  setTime?: (value: number, immediate?: boolean) => void;
}

const TurntableTpl = forwardRef<TurntableControls, TurntableProps>(
  (
    {
      images,
      time = 0,
      duration = 1,
      className = "",
    },
    ref
  ) => {
    const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({
      width: 1920,
      height: 1080
    });

    const containerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const {windowHeight, windowWidth} = useWindowSize();

    const [turntable, setTurntable] = useState<TurntableHandler | null>(null);

    const classes = classnames(style.turntable, { [className]: className });

    // We want the canvas to be responsive, so we have to trigger a rerender everytime the size changes    
    const onResize = useCallback(() => {
      if (containerRef.current && canvasRef.current) {
        const rect = containerRef.current.getBoundingClientRect();

        if (canvasSize.width !== rect.width || canvasSize.height !== rect.height) {
          setCanvasSize({ width: rect.width, height: rect.height });
          requestAnimationFrame(() => {
            turntable?.render();
          });
        }
      }
    }, [canvasSize, turntable, containerRef.current, canvasRef.current]);

    useEffect(() => {
      const observer = new ResizeObserver(() => {
        setTimeout(onResize, 0);
      });
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      onResize();

      return () => observer.disconnect();
    }, [containerRef.current, canvasSize, onresize]);

    useEffect(() => {
      if (!turntable) {
        setTurntable(new TurntableHandler(images, time, duration));
        return;
      }
    }, [turntable]);

    useEffect(() => {
      turntable?.setTime(time);
    }, [time, turntable]);

    useEffect(() => {
      turntable?.setImages(images);
    }, [images, turntable]);

    useImperativeHandle(
      ref,
      () => ({
        container: containerRef,
        setTime: turntable?.setTime.bind(turntable)
      }),
      [turntable,]
    );

    return (
      <div
        className={classes}
        ref={containerRef}
        onPointerDown={() => turntable?.onPointerDown()}
        onPointerUp={() => turntable?.onPointerUp()}
        onPointerMove={(evt) => turntable?.onPointerMove(evt)}
      >
        <canvas
          ref={(el) => {
            if (el) {
              turntable?.setCanvas(el);
              canvasRef.current = el;
            }
          }}
          width={canvasSize.width}
          height={canvasSize.height}
        />
      </div>
    );
  }
);
TurntableTpl.displayName = "Turntable";

export default TurntableTpl;