import React, { useContext, useEffect, useRef, useState } from "react";
import { Header } from "../../components/Header/Header";
import classNames from "classnames";
import Slider from "../../components/Slider/Slider";
import { Button } from "../../components/Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { Typography } from "../../components/typography";
import { SelectedVariants } from "../../components/SelectedVariants/SelectedVariants";
import { Input } from "../../components/Input/Input";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { DisclaimerSection } from "../../components/Disclaimer-Section/DisclaimerSection";
import { colorButton } from "../../enum/Button/colorButton.model";
import { DataType } from "./formType";
import { AvailableVariations } from "../Configurator/configurator.model";
import { ActiveComponentGroups } from "../Configurator/configurator.model";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { useNavigate, useParams } from "react-router-dom";
import DataContainer from "../../services/DataContainer";
import PdfDocument from "../../components/PdfDocument/PdfDocument";
import { MainTopMenus, ContactDealerSettings, SummarySettings, ContactDealerFormSettings, PrivacyPolicySettings, DisclaimerTexts, HiddenOptions, DummyData, MainSettings, MainCategories, MarketSettings, ConfiguratorOptions, RodSettings } from "../../enum/enums";
import CaptchaForForms from "../../components/CaptchaForForms/CaptchaForForms";
import {_allPreviousChoice, AdobeCampaignService} from "../../services/adobe/AdobeCampaign";
import {AdobeEventType} from "../../enum/adobe/adobeEventType";
import { CustomCheckbox } from "../../components/CustomCheckbox/CustomCheckbox";
import {Modal} from "../../components/Modal/Modal";
import { buildURLfromSelectedVariants } from "../../services/augmented-reality/augmentedReality";
import dataContainer from "../../services/DataContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel} from "swiper";
import { customBreakpoints } from "./customBreakpoints";
import { getLocales } from "../../services/ApiService";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../components/Slider/swiper.css";
import useRenderConfig from "../../utils/useRenderConfig";
import { ImageFormat, ImageType, RenderConfiguration } from "../../utils/useRenderOnDemand.types";
import useRenderOnDemand from "../../utils/useRenderOnDemand";
import JSZip from "jszip";
import { saveAs } from "file-saver";

SwiperCore.use([Mousewheel]);

function Summary() {
  const { hash, modelNameFromUrl } = useParams();
  const [loadVariations, setLoadVariations] = useState(false);
  const [renderControlKey, setRenderControlKey] = useState(0);
  const [regionSelected, setRegionSelected] = useState("");
  const [isMediumDuty, setIsMediumDuty] = useState(false);
  const {
    setActiveComponentGroups,
    dispatchStateOfComponent,
    modelName,
    data,
    currentLocale,
    activeComponentGroups,
  } = useContext(DataContainerContext);

  useEffect(()=> {
    if(modelNameFromUrl?.includes("_rod")) {
      window.location.href = window.location.href.replace("_rod", "");
    }
    if(!DataContainer.mLoadedData && data?.length > 0){
      data?.map((item: any) => {
        const mName = modelName ? modelName : modelNameFromUrl;
        if (item.product_info.description === mName) {
          dataContainer.loadData(() => {
            if(renderControlKey === 0){
              dataContainer.instance();
              const url = `${DataContainer.sectionKey(MarketSettings.MARKET_SETTINGS, MarketSettings.CONFIGURATOR_URL)}truck-builder.html#/${currentLocale.locale}`;
              dataContainer.loadSelectionFromHash(hash, url);
              setSelectedVariations(dataContainer.getSelectedVariations());
              setRenderControlKey(Math.random());
              component_groups = DataContainer.getComponentGroups()?.filter((item: any) => item.name !== HiddenOptions.HIDDEN_OPTIONS);
            }
          },
          currentLocale,
          item.id,
          item.cached_url
          );
        }
      });
    }else if(renderControlKey === 0 && DataContainer.mLoadedData){
      const defaultViewPoint = dataContainer.getProductViewPoints()?.find((vp: any) => vp.name === "010");
      dataContainer.setCurrentViewPoint(`${defaultViewPoint}_${steeringwheel_position.toUpperCase()}`);
      setRenderControlKey(Math.random());
    }
  }, [data, currentLocale, dataContainer.mLoadedData]);


  const [formData, setFormData] = useState<DataType>(DummyData);
  const [requiredFields, setRequiredFields] = useState(true);
  const [resetForm, setResetForm] = useState(true);
  const [captchaRequired, setCaptchaRequired] = useState(true);
  const [correctCaptcha, setCorrectCaptcha] = useState(true);
  const [requiredInput, setRequiredInput] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isAugmentedOpen,setIsAugmentdOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [privacyCheckboxErrorIndicator, setPrivacyCheckboxErrorIndicator] = useState<boolean>(true);
  const [sellerCheckboxErrorIndicator, setSellerCheckboxErrorIndicator] = useState<boolean>(true);
  const [marketingCheckboxErrorIndicator, setMarketingheckboxErrorIndicator] = useState<boolean>(true);
  const [isSalutationChecked, setIsSalutationChecked] = useState<boolean>(true);
  
  const formRef: any = useRef(null);
  const variationsRef: any = useRef(null);
  const resetRef: any  = useRef(null);
  const chosenDealerRef: React.LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const currentSegment = localStorage.getItem("segment");

  const getUniqueHashForSelections = () => {
    return `${window.location.href}`;
  };
  const [selectedVariations, setSelectedVariations] = useState<AvailableVariations[]>(() => { return DataContainer.getSelectedVariations();});
  const [sliderViewPoints, setSliderViewPoints] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [contactFormModalOpen, setContactFormModalOpen] = useState(false);
  const [exteriorImageSource, setExteriorImageSource] = useState<string>("");
  const [interiorImageSource, setInteriorImageSource] = useState<string>("");
  const [mapSrc, setMapSrc] = useState("");
  const [localeFromDropdown, setLocaleFromDropdown] = useState("");
  const [localeIDfromDropdown, setLocaleIDfromDropdown] = useState("");
  const [localesList, setLocalesList] = useState([]);
  const [marketsWithConfigURL, setMarketsWithConfigURL] = useState<any>([]);
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<any>({});
  const [isLoadingJPEGZip, setIsLoadingJPEGZip] = useState(false);
  const [salesforceStatus, setSalesforceStatus] = useState<string>("");
  const [salesforceMessage, setSalesforceMessage] = useState<string>("");
  let component_groups = DataContainer.getComponentGroups()?.filter((item: any) => item.name !== HiddenOptions.HIDDEN_OPTIONS);
  const navigate = useNavigate();
  const currentProductData = data.find((product: any) => product.id === DataContainer._productId);
  const adobeService = new AdobeCampaignService();
  const prevActiveComponentGroupsName = adobeService.usePrevious(activeComponentGroups.name);
  const isActiveCampaign = DataContainer?.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_CAMPAIGN);

  const pattern = /[a-zA-Z0-9]+[\\.]?([a-zA-Z0-9]+)?[\\@][a-z0-9]{2,20}[\\.][a-z]{2,9}/g;
  const locale = currentLocale?.locale?.split("-")[0];
  const market = currentLocale?.locale?.split("-")[1];

  useEffect(() => {
    setMapSrc(`https://dealerlocatorfrontend.application.volvotrucks.com/?isTruckConfigurator=true&lang=${locale}&market=${market}&ignoreLocation=true&isSalesDealersOnly=true&t=`+Math.floor(Math.random() * 1000));
    if (currentLocale?.locale === "en-en") {
      setMapSrc("https://dealerlocatorfrontend.application.volvotrucks.com/?isTruckConfigurator=true&ignoreLocation=true&isSalesDealersOnly=true&t="+Math.floor(Math.random() * 1000));
    } else if(currentLocale?.locale === "te-st") {
      setMapSrc("https://dealerlocatorfrontend.application.volvotrucks.com/?isTruckConfigurator=true&lang=sv-se&market=se&ignoreLocation=true&isSalesDealersOnly=true&t="+Math.floor(Math.random() * 1000));
    }
  },[currentLocale]);

  function mapToObject(array: any[], keyProp: string, valueProp: string, keyReplacer: { (key: number): any;}) {
    return array.reduce((acc, obj) => {
      if (obj && obj[keyProp] !== undefined && obj[valueProp] !== undefined) {
        acc[keyReplacer(obj[keyProp])] = obj[valueProp];
      }
      return acc;
    }, {});
  }

  useEffect(() => {
    const selectedVariations = DataContainer.mSelectedVariations.map(id => DataContainer?.findVariationBy(id));
    const mapToAdobeComponentStructure = {
      Engines: "Engine Model",
      Transmissions: "Transmission",
      Ext_trim_levels: "Exterior Trim",
      Headlights: "Headlights",
      Cabs: "Cab Model",
      Mirrors: "Mirrors",
      Colors: "Cab Color",
      Chassis: "Axle Type",
      Chassi_type: "Chassis Type",
      Seats: "Seats",
      Dashboard: "Dashboard",
      Int_trim_levels: "Interior Trim"
    };
    function replaceWithKey(obj: any, str: any) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === str) {
          return value;
        }
      }
      return str;
    }
    const selectedOptions = mapToObject(selectedVariations, "component_id", "original_key", (key: number) => replaceWithKey(mapToAdobeComponentStructure, DataContainer.findComponentById(key)?.name));
    if(Object.keys(selectedOptions).length !== 0) {
      selectedOptions["Segment"] = currentSegment ? currentSegment?.replace("_", " ").toUpperCase() : "No segment selected";
      selectedOptions["Model"] = modelName ? `Volvo ${modelName?.toUpperCase()}` : `Volvo ${modelNameFromUrl?.toUpperCase()}`;
      DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Summary Reached", selectedOptions);
    } 
  }, [DataContainer.mSelectedVariations]);

  const renderConfig = useRenderConfig();
  const exteriorViewPoints = DataContainer.getProductViewPoints() && DataContainer.getProductViewPoints().map((vp: { name: string }) => vp.name);
  useEffect(() => {
    setCurrentProduct(DataContainer.mLoadedData);
  },[DataContainer.mLoadedData]);

  //=========the useEffect below is temporary, will be deleted as soon as we have consistent sequence names for all trucks========
  useEffect(() => {
    if(currentProductData) {
      const truckID = currentProductData.id;
      const mediumDutyTruckIDs = [164, 165, 166, 167, 175];
      mediumDutyTruckIDs.includes(truckID) ? setIsMediumDuty(true) : setIsMediumDuty(false);
    }
  }, [currentProductData]);
  //=======================================================================================================================


  const steeringwheel_position = DataContainer.sectionKey(MarketSettings.MARKET_SETTINGS, MarketSettings.STEERINGWHEEL_POSITION);  

  const getRenderSpec = (sequence: string, forInterior?: boolean) => {
    const interiorSelectedVariations = DataContainer.getSelectedVariations().filter((variant: any) => dataContainer.findComponentGroupByName(variant?.component_group_name)?.name === ConfiguratorOptions.INTERIOR);
    const configuration: Record<string, Record<string, string>> = {};

    const mirrorsForInterior = dataContainer.findSelectedVariantByComponentNameAndComponentGroup(ConfiguratorOptions.MIRRORS, ConfiguratorOptions.EXTERIOR)?.original_key;
    const cabsForInterior = dataContainer.findSelectedVariantByComponentNameAndComponentGroup(ConfiguratorOptions.CABS, ConfiguratorOptions.EXTERIOR)?.original_key;

    const getOriginalKeysAndComponentNames = interiorSelectedVariations.map((el: { component_id: number; original_key: string; }) => {
      const componentName = dataContainer.findComponentById(el?.component_id)?.name;              
      return {
        originalKey: el?.original_key,
        componentName: componentName,
      };
    });
    configuration[ConfiguratorOptions.INTERIOR] = {};
    configuration[ConfiguratorOptions.INTERIOR][ConfiguratorOptions.MIRRORS] = mirrorsForInterior;
    configuration[ConfiguratorOptions.INTERIOR][ConfiguratorOptions.CABS] = cabsForInterior;
    getOriginalKeysAndComponentNames.forEach((singleKeyNamePair: { componentName: string | number; originalKey: string; }) => {              
      configuration[ConfiguratorOptions.INTERIOR][singleKeyNamePair.componentName] = singleKeyNamePair.originalKey;          
    });

    const interiorRenderConfig: RenderConfiguration = {
      productId: renderConfig.productId,
      componentGroups: configuration,
      productName: renderConfig.productName?.toUpperCase()
    };

    return {
      sequence: sequence,
      attributes: { 
        steeringwheel_position: steeringwheel_position,
        stage: "studio"
      },
      renderVersion: parseInt(DataContainer.sectionKey(RodSettings.ROD_SETTINGS, `${modelNameFromUrl}_renderversion`)),
      configuration: forInterior ? interiorRenderConfig : renderConfig,
      format: ImageFormat.JPEG,
      width: 2500,
      height: 1875,
      type: ImageType.Image,
      compressionQuality: 100,
    };
  };

  
  const exteriorRenderSpec = getRenderSpec("010");
  const interiorRenderSpec = getRenderSpec(isMediumDuty ? `interior_bedView_${renderConfig.productName}_${steeringwheel_position.toUpperCase()}` : "bedView", true);
  const exteriorImageInformation = useRenderOnDemand(exteriorRenderSpec);
  const interiorImageInformation = useRenderOnDemand(interiorRenderSpec);

  const selectionHash = JSON.stringify(renderConfig);
  
  useEffect(() => {
    // Reset whenever the configuration changes
    exteriorViewPoints && setExteriorImageSource("");
  }, [selectionHash]);
  
  //===============================Form Validation========================================================
  const isEmptyValue = (value: string | { name: string }) => {
    if(typeof value === "string") {
      return value === "";
    }
    else if (typeof value === "object") {
      return value.name === ""; 
    }
  };  
  
  const isRequiredFieldCorrectlyFilled = (data: DataType): boolean => {
    const requiredFields = Object.entries(data).filter(([key, value]) => DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM,`contact_dealer_${key}_required`) === "true");
    const allRequiredFieldsAreFilled = requiredFields.map(([key, value]: any) => !isEmptyValue(value));
    return allRequiredFieldsAreFilled.every((field: any) => field == true);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === "privacyText" || name === "pronounTitle") {
      setPrivacyCheckboxErrorIndicator(!e.target.checked);   
    } else if (name === "sellerText") {
      setSellerCheckboxErrorIndicator(!e.target.checked);
    } else if (name === "marketingCheckbox") {
      setMarketingheckboxErrorIndicator(!e.target.checked);
    }
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };  

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsSalutationChecked(!isSalutationChecked);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkEmailValidity = () => {
    const result = pattern.test(formData.email);
    setIsEmailValid(result);
    return result;
  };

  const onChangeDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeRegions = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, chosen_dealer: {name: e.target.value} });
    setRegionSelected(e.target.value);
  };  

  const getDealerInformation = (message: any) => {    
    if (message.data.from && message.data.from === "dealer_locator") {            
      setFormData({ ...formData, chosen_dealer: message.data.data });
      chosenDealerRef.current && chosenDealerRef.current.scrollIntoView({behavior: "smooth", block: "center"});
    }    
  };  

  const allSalesforceRequiredFieldsAreFilled = () => {
    const inputFields = document.getElementsByTagName("input");    
    const selectFields = document.getElementsByTagName("select");

    const filteredInputRequiredFields = Object.values(inputFields).filter((field: any) => DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, `contact_dealer_${field.name.toLowerCase()}_required`) === "true");
    
    const inputRequiredFields = Object.values(filteredInputRequiredFields).map((field: any) => {   
      if(field?.value === "") {
        return false;
      } else if (field.value === "on" && field?.checked == false) {
        return false;
      } else {
        return true;
      }
    });

    const selectRequiredFields = Object.values(selectFields).filter((field: any) => DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, `contact_dealer_${field.name.toLowerCase()}_required`) === "true")
      .map((field: any) => field.value).every((field: any) => field !== "");    
    return (inputRequiredFields.every((field) => field == true) && selectRequiredFields == true);
  }; 

  //======================================Form Validation(above)===================================================
  const submitForm = (data: DataType) => {
    if(showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE)) {
      if(allSalesforceRequiredFieldsAreFilled() && checkEmailValidity() && correctCaptcha) {
        const requestedFrom = "ContactForm";
        DataContainer.sendDataToSalesForce(data, requestedFrom, currentLocale.locale, getUniqueHashForSelections(), exteriorImageSource, async (res: any) => {
          if (res) {
            setSalesforceStatus(res.Status);
            setSalesforceMessage(res.Message);
            setContactFormModalOpen(true);
            setFormData(DummyData);
            setResetForm(!resetForm);
            setRenderControlKey(Math.random());
            resetRef.current.reset();
            DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Form Submit");
            setRequiredInput(false);
          } else {
            console.error("Something went wrong with your request");
          }
        });
      } else {
        setRequiredFields(false);
        setRequiredInput(true);
      }
    } else {
      if(captchaRequired && correctCaptcha && isRequiredFieldCorrectlyFilled(data) && checkEmailValidity() && (isActiveCampaign === "true" ? (data.privacyText == true && data.sellerText == true) : data.pronounTitle == true)) {
        DataContainer.sendContactInfoAndConfigurationToSeller(data, isActiveCampaign, currentLocale.locale, getUniqueHashForSelections(), exteriorImageSource);
        setRequiredFields(true);
        setFormData(DummyData);
        setResetForm(!resetForm);
        setRenderControlKey(Math.random());
        resetRef.current.reset();
        setRequiredInput(false);
        DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Form Submit");
        setTimeout(() => {
          setContactFormModalOpen(true);
        }, 500);
      } else {
        setRequiredFields(false);
        setRequiredInput(true);
      }
    }
  };

  const showHideMedia = () => {
    setIsShareOpen((prevState) => {
      return !prevState;
    });
  };

  const showHideButtons = () => {
    setIsAugmentdOpen((prevState) => {
      return !prevState;
    });
  };

  window.addEventListener("message", getDealerInformation, true);

  if (!DataContainer.getSelectedVariations().length) {
    DataContainer.loadSelectionFromHash(hash);
  }

  const scrollToMap = () : any => {
    formRef.current && formRef.current.scrollIntoView({behavior: "smooth"});
  };

  function componentGroupsClickEvent(componentGroups: ActiveComponentGroups) {
    setActiveComponentGroups(componentGroups);
    dispatchStateOfComponent({
      type: "resetComponent",
      payload: 0
    });

    navigate(`/${currentLocale.locale}/configurator/${modelName ? modelName : modelNameFromUrl}`);
  }
  const getMenuLinks = () : any => {
    return [{ name: DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, MainTopMenus.YOUR_VOLVO)+` ${currentProductData ? currentProductData?.product_info?.name.replace("Volvo",""): ""}`, link: "#", activeClass: true },
      { name: DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, MainTopMenus.OVERVIEW_TITLE), link: "#overview", activeClass: false }];
  };  

  const image1 = useRenderOnDemand(
    getRenderSpec("010"));

  const image2 = useRenderOnDemand(
    getRenderSpec("020"));

  const image3 = useRenderOnDemand(
    getRenderSpec("030"));

  const image4 = useRenderOnDemand(
    getRenderSpec("040"));

  const image5 = useRenderOnDemand(
    getRenderSpec("050"));

  const image6 = useRenderOnDemand(
    getRenderSpec(isMediumDuty ? `interior_driverView_${renderConfig.productName}_${steeringwheel_position.toUpperCase()}` : "driverView", true));

  const image7 = useRenderOnDemand(
    getRenderSpec(isMediumDuty ? `interior_bedView_${renderConfig.productName}_${steeringwheel_position.toUpperCase()}` : "bedView", true));

  const images: string[] = [];
  images.push(image1.urlAssets[0], image2.urlAssets[0], image3.urlAssets[0], image4.urlAssets[0], image5.urlAssets[0], image6.urlAssets[0], image7.urlAssets[0]);
  // const imagesReady = images.every((image: string) => image !== "" && image !== undefined);

  const downloadImages = async (format: string) => {
    format === "png" ? setIsLoadingZip(true) : setIsLoadingJPEGZip(true);
    const zip = new JSZip();

    async function addImageToZip(url: string, fileName: string) {
      const response = await fetch(url);
      const blob = await response.blob();
      zip.file(fileName, blob);
    }

    const promises: any = [];
    images.forEach(function (result, idx) {
      const url = result;
      const fileName = `${currentProductData.product_info.name} ${idx+1}${format === "png" ? ".png" : ".jpg"}`;
      promises.push(addImageToZip(url, fileName));
    });

    const res = await Promise.allSettled(promises);
    if (res) {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${currentProductData.product_info.name}`);
    }

    format === "png" ? setIsLoadingZip(false) : setIsLoadingJPEGZip(false);
  };
  
  useEffect(() => {
    setExteriorImageSource(exteriorImageInformation.urlAssets[0]);
    setInteriorImageSource(interiorImageInformation.urlAssets[0]);
  }, [exteriorImageInformation.urlAssets, interiorImageInformation.urlAssets]);

  useEffect(() => {
    //ignore cases when data are not loaded.
    if(!DataContainer.mLoadedData){
      return;
    }
    if(loadVariations && !DataContainer.getSelectedVariations().length) {
      setLoadVariations(false);
    } else {
      setSelectedVariations(DataContainer.getSelectedVariations());
    }
    if (DataContainer?.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_CAMPAIGN) && (
      (DataContainer?.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_CAMPAIGN) !== ContactDealerSettings.ACTIVE_CAMPAIGN))) {
      adobeService.sendAnalytics(AdobeEventType.PAGE_ENTERED, {category: prevActiveComponentGroupsName}, _allPreviousChoice);
    }
  }, [loadVariations]);


  useEffect(() => {
    //ignore cases when data are not loaded.
    if(!DataContainer.mLoadedData){
      return;
    }

    if (DataContainer.mLoadedData) {
      if(!sliderViewPoints?.length) setSliderViewPoints(DataContainer.getProductViewPoints());
    }

    getLocales().then( async (res) => {
      const allLocales = res.data.data;
      const getAllMarketsData = await dataContainer.getApplicationDataForAllMarkets();      
      const getLocalesWithConfigURL = getAllMarketsData.find((options: {id: number, name: string}) => options.name === "Market.Settings").keys.find((key: {id: number, key: string}) => key.key === "configurator_url").captions;
      const filteredLocales = allLocales.filter((singleLocale: any) => getLocalesWithConfigURL.some((localeWithURL: any) => localeWithURL.locale_id === singleLocale.id));
      setLocalesList(filteredLocales);
      setMarketsWithConfigURL(getLocalesWithConfigURL);
    });
  }, [DataContainer.mLoadedData, sliderViewPoints]);  

  const getDropdownRedirectionURL = (id: any) => { 
    return marketsWithConfigURL.find((singleMarket: {id: number, locale_id: number, value: string}) => singleMarket?.locale_id == id )?.value;
  };  

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const forbiddenComponents = ["Chassi_type", "Hidden_options", "Colors"];
  const filteredVariations = selectedVariations.filter((variation) => !forbiddenComponents.includes(dataContainer.findComponentById(variation?.component_id)?.name));
  const translateComponentName = (variant: AvailableVariations) => {
    if(!dataContainer.variationBelongsToColorizer(variant)) {
      const component = DataContainer.findComponentById(variant?.component_id);
      const componentGroup = DataContainer.getComponentGroups().find((cg: any) => cg.id === component.group_id);
      return DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, `${componentGroup.name.toLowerCase()}_${component.name.toLowerCase()}_title`);
    } else {
      return DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, "exterior_colors_title");
    }
  };

  const camelize = (str: string, makeFirstUpper: boolean) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return "";
      return index === 0 && !makeFirstUpper ? match.toLowerCase() : match.toUpperCase();
    });
  };

  const translateVariation = (variation: AvailableVariations) => {
    const component = DataContainer.findComponentById(variation.component_id);
    if(!dataContainer.variationBelongsToColorizer(variation)) {
      let sectionKey = `Options.${component.name}`;
      const componentGroup = DataContainer.findComponentGroupByComponentId(variation.component_id);
      if (["interior", "exterior"].includes(componentGroup.name.toLowerCase())){
        if (["Ext_trim_levels", "Int_trim_levels"].includes(component.name)) {
          sectionKey = `${`Options.${componentGroup.name}${camelize(component.name.split("_").slice(1).join("_").replaceAll("_"," "), true)}`}`;
        }
      }
      let variationTranslation = DataContainer.sectionKey(
        sectionKey,`${variation.original_key}_title`) + " " + DataContainer.sectionKey(sectionKey,`${variation.original_key}`);
      if(variationTranslation.includes(variation.original_key)){
        variationTranslation = DataContainer.sectionKey(sectionKey,`${variation.original_key}`);
      }
      return variationTranslation;
    } else {
      return variation.key;
    }
  };

  const showCTA = (sectionName: string, key: string) => {
    if(DataContainer?.sectionKey(sectionName, key) === "true"){      
      return true;
    }
    return false;
  };  
  
  return ( renderControlKey === 0 ? 
    <div className="flex absolute z-50 flex-col items-center justify-center w-full h-full bg-white text-black">
      <div>...</div>
    </div> :
    <div className="h-screen">
      <Header
        key={renderControlKey}
        title={dataContainer.sectionKey(MainSettings.MAIN_CATEGORIES, MainCategories.SUMMARY_CATEGORY) ? dataContainer.sectionKey(MainSettings.MAIN_CATEGORIES, MainCategories.SUMMARY_CATEGORY) : "Summary"}
        links={getMenuLinks()}
        onClickEvent={() => ""}
        refToScroll={variationsRef}
        onSidebarItemClickEvent={(component) => componentGroupsClickEvent(component)}
        listSidebar={DataContainer?.getComponentGroups()?.filter((item: any) => item.name !== HiddenOptions.HIDDEN_OPTIONS)}
      />
      {sliderViewPoints?.length &&
        <div className="object-cover relative">
          <Slider
            onSummary={true}
            environment={"studio"}
            isMediumDuty={isMediumDuty}
          />
        </div>
      }
      <div className="top-0 z-20">
        <div className="flex sm:flex-row flex-col justify-center items-center py-8 !border-b border-grey-light">
          <div className="relative flex flex-col sm:flex-row">
            <div className="absolute flex flex-col w-full overflow-x-hidden h-11 top-0 rounded-3xl">
              <div
                className={classNames(
                  "absolute items-center justify-center transition z-20 translate-x-0 overflow-x-hidden ease-in-out duration-500 bg-white flex w-full h-11 top-0 left-0 rounded-3xl slider",
                  { "-translate-x-full": !isAugmentedOpen },
                )}
              >
                {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_APPSTORE_BUTTON) &&
                    <Button
                      variant={VarianButtonEnum.DEFAULT}
                      icon={IconModelEnum.AppStore}
                      bgColor={colorButton.WHITE}
                      customClassNames="ml-8 mr-6 flex-row-reverse"
                      text={SummarySettings.APP_STORE}
                      onMobileView={true}
                      customClassNamesSpan="pr-5"
                      onClickEvent={() => window.open(buildURLfromSelectedVariants(currentLocale.locale, modelName, hash!, component_groups), "_blank")}
                    />

                }
                {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_GOOGLESTORE_BUTTON) &&
                    <Button
                      variant={VarianButtonEnum.DEFAULT}
                      icon={IconModelEnum.GoogleStore}
                      bgColor={colorButton.WHITE}
                      customClassNames="ml-8 flex-row-reverse"
                      customClassNamesSpan="pr-5"
                      onMobileView={true}
                      text={SummarySettings.PLAY_STORE}
                      onClickEvent={()=> window.open(buildURLfromSelectedVariants(currentLocale.locale, modelName, hash!, component_groups), "_blank")}
                    />
                }
              </div>
            </div>

            { isAugmentedOpen &&
                <Button
                  variant={VarianButtonEnum.SECONDARYICONBUTTON}
                  icon={IconModelEnum.CLOSE}
                  customClassNames="p-4 z-50"
                  onClickEvent={ () => {setIsAugmentdOpen(false);}}
                />
            }
            <div className="flex relative flex-col sm:flex-row items-center justify-center">
              {
                isMobile && (!!DataContainer?.sectionKey("ContactDealer.Settings", "active_appstore_button") || !!DataContainer?.sectionKey("ContactDealer.Settings", "active_googlestore_button")) &&
                <Button
                  variant={VarianButtonEnum.PRIMARY}
                  text="Augmented reality"
                  icon={IconModelEnum.AR}
                  customClassNames={classNames(
                    "bg-gray-900 mr-0 max-h-11 sm:mr-6 z-10",
                    {"hidden sm:inline" : isAugmentedOpen}
                  )}
                  onClickEvent={()=> window.open(buildURLfromSelectedVariants(currentLocale.locale,  modelNameFromUrl || "", hash!, component_groups), "_blank")}
                />
              }
              {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.CONTACT_DEALER_LINK_BUTTON) &&
                <Button
                  variant={VarianButtonEnum.PRIMARY}
                  text={DataContainer.sectionKey(SummarySettings.SUMMARY, SummarySettings.CONTACT_DEALER_BUTTON)}
                  icon={IconModelEnum.MESSAGE}
                  customClassNames="bg-blue-light align-center max-h-11 mt-3 sm:mt-0 px-4"
                  onClickEvent={scrollToMap}
                />
              }
            </div>
            <div className="flex sm:mt-0 mt-3 justify-center relative">
              {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_DOWNLOAD_PDF) && renderControlKey === 0 ? <></> : <PdfDocument
                exteriorImg={exteriorImageSource}
                interiorImg={interiorImageSource}
                variants={filteredVariations}
                title={currentProductData?.product_info?.name}
                disabledAndLoading={!((exteriorImageSource || interiorImageSource) && selectedVariations?.length > 0)}
              />}
              {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_DOWNLOAD_PRODUCT_IMAGE) && 
                <Button
                  variant={VarianButtonEnum.SECONDARYICONBUTTON}
                  icon={isLoadingZip ? IconModelEnum.LOADING : IconModelEnum.PNG}
                  customClassNames="mx-3"
                  onClickEvent={() => {
                    downloadImages("png");
                    DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Download Configuration", null, "PNG");
                  }
                  }
                />
              }
              {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_DOWNLOAD_PRODUCT_IMAGE) && 
                <Button
                  variant={VarianButtonEnum.SECONDARYICONBUTTON}
                  icon={isLoadingJPEGZip ? IconModelEnum.LOADING : IconModelEnum.JPG}
                  customClassNames="mx-3"
                  onClickEvent={() => {
                    downloadImages("jpg");
                    DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Download Configuration", null, "JPG");
                  }
                  }
                />
              }
              <div className="relative">
                {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_SHARE_BUTTON) &&    
                <Button
                  variant={VarianButtonEnum.ICONBUTTON}
                  icon={IconModelEnum.SHARE}
                  customClassNames="relative mx-2.5 z-10"
                  onClickEvent={showHideMedia}
                />
                }
                {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_ENVELOPE_ICON) && 
                  <div className="absolute w-20 2md:w-36 overflow-x-hidden h-11 left-4 top-0 rounded-full">
                    <div
                      className={classNames(
                        "absolute flex items-center transition translate-x-0 overflow-x-hidden ease-in-out duration-500 bg-gray-100 w-24 h-11 top-0 left-0 z-0 -right-20 rounded-full slider",
                        { "-translate-x-32": !isShareOpen }
                      )}
                    >           
                      <Button
                        variant={VarianButtonEnum.DEFAULT}
                        icon={IconModelEnum.MESSAGEBLACK}
                        bgColor={colorButton.WHITE}
                        onClickEvent={() => setIsOpen(true)}
                        customClassNames={classNames(
                          { "pointer-events-none opacity-50": !DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_EMAIL_FOR_LOCALES) },
                          "ml-14 mr-6")}
                      />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-20 pt-28 text-center text-light-grey" id="overview" ref={variationsRef}>
        <Typography tag="h3" className="text-3xl3 font-bold mb-3.5 uppercase">
          {currentProductData ? currentProductData?.product_info.name: ""}
        </Typography>
        <Typography tag="h3" className="text-3xl">
          {DataContainer.sectionKey(SummarySettings.SUMMARY, SummarySettings.OVERVIEW_CONFIGURATION)}
        </Typography>
      </div>
      <div className={classNames(
        "flex pb-6 mb-24 mx-9",
        {"mb-2" : dataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SELECT_MARKET) === "true"})}
      >
        <Swiper
          direction="horizontal"
          mousewheel={{releaseOnEdges: false}}
          freeMode={true}
          slidesPerView={1.5}
          spaceBetween={16}
          breakpoints={customBreakpoints}
        >
          {filteredVariations?.map((variant: AvailableVariations, index: number) => (
            <SwiperSlide key={variant?.id || index} className="mr-0 ">
              <SelectedVariants
                image={variant?.assets[0]?.preview_image_url}
                category={translateComponentName(variant)}
                readMoreLink={
                  (DataContainer.sectionKey(SummarySettings.SUMMARY, `readmore_${currentProductData?.product_info?.description}`))}
                subCategory={translateVariation(variant)}
                backgroundColor={dataContainer.variationBelongsToColorizer(variant) && `#${variant.original_key.slice(2)}`}
                key={variant?.id || index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_CONTACT_FORM) && 
        <div>
          {dataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SELECT_MARKET) !== "true" ? 
            <div className="flex flex-col 2md:flex-row bg-primary" ref={formRef}>
              {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_CHOOSE_DEALER) && 
                <div className="2md:w-1/2">
                  <iframe
                    className="w-full h-[950px] 2md:h-full" src={mapSrc}
                  />
                </div>
              }
              <div className="2md:w-1/2 p-4 md:p-16 xl:p-32 mx-auto">
                <div className="text-light-grey  text-center xl:text-left lg:text-left">
                  <Typography tag="h3" className="uppercase text-xl text-light-grey">
                    {DataContainer.sectionKey(
                      ContactDealerFormSettings.CONTACT_DEALER_FORM,
                      ContactDealerFormSettings.CONTACT_DEALER_TITLE
                    )}
                  </Typography>
                  <Typography tag="p" className="text-2xl2 leading-medium py-4">
                    {DataContainer.sectionKey(
                      ContactDealerFormSettings.CONTACT_DEALER_FORM,
                      ContactDealerFormSettings.CONTACT_DEALER_TEXT
                    )}
                  </Typography>
                </div>
                {isActiveCampaign === "true" ?
                  <form className="mb-10" ref={resetRef} key={renderControlKey}>
                    <Input
                      variant="primary"
                      type="text"
                      name="name"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_FIRSTNAME_TEXT
                      )}
                      value={formData.name}
                      onChangeEvent={onChange}
                      onClickEvent={() => DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Form Start")}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_NAME_REQUIRED) === "true" && requiredInput}
                    />
                    <Input
                      variant="primary"
                      type="text"
                      name="lastname"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_TEXT
                      )}
                      value={formData.lastname}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_REQUIRED) === "true" && requiredInput}
                    />
                    <div className="mb-10 cursor-pointer">
                      <p className="text-xs text-blue-light uppercase mt-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_PRONOUN_TITLE)}</p>
                      <Input
                        variant="default"
                        type="radio"
                        name="aanhef"
                        value={formData.aanhef}
                        onChangeEvent={(e) => onRadioChange(e)}
                        checked={isSalutationChecked}
                        customClassNames="cursor-pointer"
                        label={DataContainer.sectionKey(
                          ContactDealerFormSettings.CONTACT_DEALER_FORM,
                          ContactDealerFormSettings.CONTACT_DEALER_PRONOUN_MR_TEXT
                        )}
                      />
                      <Input
                        variant="default"
                        type="radio"
                        name="aanhef"
                        value={formData.aanhef}
                        onChangeEvent={(e) => onRadioChange(e)}
                        checked={!isSalutationChecked}
                        customClassNames="cursor-pointer"
                        label={DataContainer.sectionKey(
                          ContactDealerFormSettings.CONTACT_DEALER_FORM,
                          ContactDealerFormSettings.CONTACT_DEALER_PRONOUN_MRS_TEXT
                        )}
                      />
                    </div>
                    <Input
                      variant="primary"
                      type="email"
                      name="email"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_EMAIL_TEXT
                      )}
                      value={formData.email}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_REQUIRED) === "true" && requiredInput}
                    />
                    {(!isEmailValid && formData.email !== "") &&
                      <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_INVALID_TEXT)}</span>
                    }
                    <Input
                      variant="primary"
                      type="number"
                      name="phone"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_PHONE_TEXT
                      )}
                      onKeyDownEvent={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()}
                      value={formData.phone}
                      onChangeEvent={onChange}
                    />
                    <Input
                      variant="primary"
                      type="text"
                      name="company"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_COMPANY_TEXT
                      )}
                      value={formData.company}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_COMPANY_REQUIRED) === "true" && requiredInput}
                    />
                    <Input
                      variant="primary"
                      type="text"
                      name="establishment"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_ESTABLISHMENT_TEXT
                      )}
                      value={formData.establishment}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ESTABLISHMENT_REQUIRED) === "true" && requiredInput}
                    />
                    <Typography tag="p" className="text-2xs pt-4">
                      {DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_REQUIRED_TIP_TEXT
                      )}
                    </Typography>
                    <div>
                      <div ref={chosenDealerRef} className="flex justify-between">
                        <Typography tag="p" className="text-3xl py-7 pr-2 text-black-medium">
                          {DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_CHOSEN_DEALER_TEXT
                          )}
                          {/* {(formData?.chosen_dealer?.name === "" && requiredInput) && <span className="text-red flex text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_CHOSEN_DEALER_REQUIRED_TEXT )}</span>} */}
                        </Typography>
                        {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_CHOOSE_DEALER) &&
                            <Typography tag="p" className="text-3xl py-7 text-black-medium">
                              {formData?.chosen_dealer?.name}
                            </Typography>
                        }
                      </div>
                    </div>
                    <div className="flex flex-col mt-10">
                      <a href={`https://www.volvotrucks.${currentLocale.locale === "en-en" ? "com" : market}/${currentLocale.locale}/tools/privacy.html`} target="_blank" rel="noreferrer" className="underline underline-offset-1 text-2xl text-blue-700 mb-4">Privacy Statement</a>
                      <div className='relative pb-10'>
                        <div className="flex flex-col gap-6">
                          <CustomCheckbox
                            name="privacyText"
                            label={DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_OPTIN_READ_AND_AGREE_PRIVACY_TEXT
                            )}
                            onChangeEvent={(e) => onCheckboxChange(e, "privacyText")}
                            customClassNames={classNames({ "border-red rounded": privacyCheckboxErrorIndicator && requiredInput })}
                            customLabelClasses={classNames({ "text-red": privacyCheckboxErrorIndicator && requiredInput })}
                          />
                          <CustomCheckbox
                            name="sellerText"
                            label={DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_OPTIN_RECORD_BY_SELLER_TEXT
                            )}
                            onChangeEvent={(e) => onCheckboxChange(e, "sellerText")}
                            customClassNames={classNames({ "border-red rounded": sellerCheckboxErrorIndicator && requiredInput })}
                            customLabelClasses={classNames({ "text-red": sellerCheckboxErrorIndicator && requiredInput })}
                          />
                          <CustomCheckbox
                            name="newsText"
                            label={DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_OPTIN_VOLVO_TRUCKS_NEWS_TEXT
                            )}
                            onChangeEvent={(e) => onCheckboxChange(e, "newsText")}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <CaptchaForForms required={!(captchaRequired || requiredFields)} captcha={(e) => setCaptchaRequired(e)} resetCaptcha={resetForm} setCorrectCaptcha={(e) => setCorrectCaptcha(e)} correctCaptcha={correctCaptcha}/> */}
                    <Button
                      variant={VarianButtonEnum.PRIMARY}
                      text={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_SEND_TEXT
                      )}
                      icon={IconModelEnum.SUBMIT}
                      customClassNames="bg-blue-light"
                      onClickEvent={() => {
                        submitForm(formData);
                      }}
                    />
                    {contactFormModalOpen && 
                      <Modal
                        setContactFormModalOpen={setContactFormModalOpen}
                        contactFormModalOpen={contactFormModalOpen}
                      />
                    }
                  </form>
                  :
                  <form className="pb-7" ref={resetRef} key={renderControlKey}>
                    <Input
                      variant="primary"
                      type="text"
                      name="name"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_FIRSTNAME_TEXT
                      )}
                      value={formData.name}
                      onChangeEvent={onChange}
                      onClickEvent={() => DataContainer.sendDataToAdobeDataLayer("applicationInteraction", "7", "Summary", "Form Start")}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_NAME_REQUIRED) === "true" && requiredInput}
                    />
                    {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) &&
                      <Input
                        variant="primary"
                        type="text"
                        name="lastname"
                        label={DataContainer.sectionKey(
                          ContactDealerFormSettings.CONTACT_DEALER_FORM,
                          ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_TEXT
                        )}
                        value={formData.lastname}
                        onChangeEvent={onChange}
                        isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_REQUIRED) === "true" && requiredInput}
                      />
                    }
                    <Input
                      variant="primary"
                      type="email"
                      name="email"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_EMAIL_TEXT
                      )}
                      value={formData.email}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_REQUIRED) === "true" && requiredInput}
                    />
                    {(!isEmailValid && formData.email !== "") &&
                      <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_INVALID_TEXT)}</span>
                    }
                    <Input
                      variant="primary"
                      type="number"
                      name="phone"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_PHONE_TEXT
                      )}
                      onKeyDownEvent={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()}
                      value={formData.phone}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_PHONE_REQUIRED) === "true" && requiredInput}
                    />
                    <Input
                      variant="primary"
                      type="text"
                      name="zip"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_ZIP_TEXT
                      )}
                      value={formData.zip}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ZIP_REQUIRED) === "true" && requiredInput}
                    />
                    {!(showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE)) && <Input
                      variant="primary"
                      type="text"
                      name="country"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_COUNTRY_TEXT
                      )}
                      value={formData.country}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_COUNTRY_REQUIRED) === "true" && requiredInput}
                    />}
                    <Input
                      variant="primary"
                      type="text"
                      name="city"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_CITY_TEXT
                      )}
                      value={formData.city}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_CITY_REQUIRED) === "true" && requiredInput}
                    />
                    <Input
                      variant="primary"
                      type="text"
                      name="company"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_COMPANY_TEXT
                      )}
                      value={formData.company}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_COMPANY_REQUIRED) === "true" && requiredInput}
                    />
                    {!showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) &&
                    // ?
                      <Dropdown
                        variant="primary"
                        label={DataContainer.sectionKey(
                          ContactDealerFormSettings.CONTACT_DEALER_FORM,
                          ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_TEXT
                        )}
                        name="segment"
                        selectedOption={formData?.segment}
                        onChangeEvent={onChangeDropdown}
                        options={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_LIST).split(",")}
                        isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_REQUIRED) === "true" && requiredInput}
                      /> 
                      // :
                      // <Dropdown
                      //   variant="primary"
                      //   label={DataContainer.sectionKey(
                      //     ContactDealerFormSettings.CONTACT_DEALER_FORM,
                      //     ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_TEXT
                      //   )}
                      //   name="salesforceSegment"
                      //   selectedOption={formData?.salesforceSegment}
                      //   onChangeEvent={onChangeDropdown}
                      //   options={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.SALESFORCE_SEGMENT_LIST).split(",")}
                      //   isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_REQUIRED) === "true" && requiredInput}
                      // />
                    }
                    {/* {(showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) && showCTA(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SHOW_ROLE_LIST)) &&
                      <div>
                        <Dropdown
                          variant="primary"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_ROLE_TEXT
                          )}
                          name="role"
                          selectedOption={formData?.role}
                          onChangeEvent={onChangeDropdown}
                          options={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ROLE_LIST).split(",")}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ROLE_REQUIRED) === "true" && requiredInput}
                        />
                      </div>
                    } */}
                    {DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.REGIONS_IN_FORM) !== "false" && 
                      <Dropdown 
                        name="regions"
                        variant={"primary"} 
                        label={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_REGIONS_TEXT)} 
                        options={DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.REGIONS_LIST).split(", ")} 
                        onChangeEvent={onChangeRegions}
                        selectedOption={regionSelected}
                        isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_REGIONS_REQUIRED) === "true" && requiredInput}
                      />
                    }
                    <Input
                      variant="primary"
                      type="textarea"
                      name="message"
                      label={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_MESSAGE_TEXT
                      )}
                      value={formData.message}
                      onChangeEvent={onChange}
                      isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_MESSAGE_REQUIRED) === "true" && requiredInput}
                    />
                    <Typography tag="p" className="text-2xs">
                      {DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_REQUIRED_TIP_TEXT
                      )}
                    </Typography>
                    <div>
                      {showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SHOW_CHOOSE_DEALER) &&
                      <div ref={chosenDealerRef} className="flex justify-between">
                        <Typography tag="p" className="text-3xl py-7 pr-2 text-black-medium">
                          {DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_CHOSEN_DEALER_TEXT
                          )}
                          {(formData?.chosen_dealer?.name === "" && requiredInput) && <span className="text-red flex text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_CHOSEN_DEALER_REQUIRED_TEXT )}</span>}
                        </Typography>
                        <Typography tag="p" className="text-3xl py-7 text-black-medium">
                          {formData?.chosen_dealer?.name}
                        </Typography>
                      </div>
                      }

                    </div>
                    {(showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE)) ? 
                      <Typography tag="p" className="text-2xl text-light-grey pb-7">
                        {DataContainer.sectionKey(
                          PrivacyPolicySettings.PRIVACY_POLICY,
                          PrivacyPolicySettings.PRIVACY_POLICY_TEXT
                        )}<span><a href={`https://www.volvogroup.com/en/tools/privacy/privacy-${(currentLocale.locale === "nl-be" || currentLocale.locale === "fr-be") ? locale : market}.html`} target="_blank" rel="noreferrer" className="underline underline-offset-1 text-2xl text-blue-700 mb-4" >{`(https://www.volvogroup.com/en/tools/privacy/privacy-${market}.html)`}</a></span>
                      </Typography> 
                      : 
                      <div className="my-5">
                        <Typography tag="p" className="text-3xl text-black-medium">
                          {DataContainer.sectionKey(
                            PrivacyPolicySettings.PRIVACY_POLICY,
                            PrivacyPolicySettings.PRIVACY_POLICY_TITLE
                          )}
                        </Typography>
                        <Typography tag="p" className="text-2xl text-light-grey pb-7">
                          {DataContainer.sectionKey(
                            PrivacyPolicySettings.PRIVACY_POLICY,
                            PrivacyPolicySettings.PRIVACY_POLICY_TEXT
                          )}
                        </Typography>
                      </div>
                    }
                    {(!showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE)) &&
                      <div className="flex flex-col py-6">
                        <div className='relative'>
                          <CustomCheckbox
                            name="pronounTitle"
                            label={DataContainer.sectionKey(PrivacyPolicySettings.PRIVACY_POLICY, PrivacyPolicySettings.PRIVACY_POLICY_CHECKBOX_TEXT)}
                            onChangeEvent={(e) => onCheckboxChange(e, "pronounTitle")}
                            customClassNames={classNames({ "border-red rounded": privacyCheckboxErrorIndicator && requiredInput })}
                            customLabelClasses={classNames({ "text-red": privacyCheckboxErrorIndicator && requiredInput })}
                          />
                        </div>
                        {(privacyCheckboxErrorIndicator && requiredInput) && <span className="text-red pt-2 text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ACCEPT_TERMS_REQUIRED_TEXT)}</span>}
                      </div>
                    }
                    {(showCTA(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX) || showCTA(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE)) &&
                      <div className="flex flex-col pb-6">
                        <div className='relative'>
                          <CustomCheckbox
                            name="marketingCheckbox"
                            label={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_TEXT)}
                            onChangeEvent={(e) => onCheckboxChange(e, "marketingCheckbox")}
                            customClassNames={classNames({ "border-red rounded": marketingCheckboxErrorIndicator && requiredInput && (DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED) === "true") })}
                            customLabelClasses={classNames({ "text-red": marketingCheckboxErrorIndicator && requiredInput && (DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED) === "true")})}
                          />
                        </div>
                        {(marketingCheckboxErrorIndicator && requiredInput && showCTA(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED)) && <span className="text-red pt-2 text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED_TEXT)}</span>}
                      </div>
                    }
                    <CaptchaForForms required={!(captchaRequired || requiredFields)} captcha={(e) => setCaptchaRequired(e)} resetCaptcha={resetForm} setCorrectCaptcha={(e) => setCorrectCaptcha(e)} correctCaptcha={correctCaptcha}/>
                    <Button
                      variant={VarianButtonEnum.PRIMARY}
                      text={DataContainer.sectionKey(
                        ContactDealerFormSettings.CONTACT_DEALER_FORM,
                        ContactDealerFormSettings.CONTACT_DEALER_SEND_TEXT
                      )}
                      icon={IconModelEnum.SUBMIT}
                      customClassNames="bg-blue-light"
                      onClickEvent={() => {
                        submitForm(formData);
                      }}
                    />
                    {(contactFormModalOpen) ? (
                      <Modal
                        setContactFormModalOpen={setContactFormModalOpen}
                        contactFormModalOpen={contactFormModalOpen}
                        salesforceStatus={salesforceStatus} 
                        salesforceMessage={salesforceMessage}
                      />

                    ) : null}
                  </form> 
                }

                <DisclaimerSection
                  positionOfTitle="left"
                  positionOfContent="left"
                  links={[
                    {
                      title: DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_BUTTON),
                      link: DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_URL),
                    },
                    {
                      title:  DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_BUTTON),
                      link: DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_URL),
                    },
                  ]}
                  positionOfLinks="left"
                  isSummary
                />
              </div>
            </div> 
            :
            <div className="pb-20">
              <div className="pb-10 md:w-1/5 w-4/5 mx-auto text-center items-center">
                <Dropdown
                  selectedOption={localeFromDropdown}
                  variant="secondary"
                  label="SELECT MARKET"
                  labelTransitions={false}
                  onChangeEvent={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setLocaleFromDropdown(e.currentTarget.value);
                    setLocaleIDfromDropdown(e.target.options[e.currentTarget.selectedIndex].id);
                  }}
                  toggleTextCenter
                  options={localesList}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  variant={VarianButtonEnum.PRIMARY}
                  onClickEvent={() => {
                    window.open(getDropdownRedirectionURL(localeIDfromDropdown), "_blank");
                  }}
                  text="CONFIRM"
                />
              </div>
            </div>
          }
        </div>
      }
      {
        isOpen && 
          <Modal 
            setIsOpen={setIsOpen} 
            configuration={getUniqueHashForSelections()} 
            serviceActiveCampaign={isActiveCampaign} 
            locale={currentLocale.locale} 
            frontalTruckImage={exteriorImageSource} 
          />
      }
    </div>
  );
}

export default Summary;