import axios from "axios";
import xmlToJs from "xml-js";
export default {
  _mState: "passive",
  _productId: 24,
  _applicationId: process.env.REACT_APP_APPLICATION_ID,
  _applicationData: process.env.REACT_APP_API_APPLICATION_DATA,
  _singleProductBaseUrl: process.env.REACT_APP_API_SINGLE_PRODUCT_BASE_URL,
  _apiKey: process.env.REACT_APP_API_KEY,
  _API_ONE_RODMANAGER: process.env.REACT_APP_API_ONE_RODMANAGER,
  _API_KEY_ROD: process.env.REACT_APP_API_KEY_ROD,
  _APP_ID_ROD: process.env.REACT_APP_ID_ROD,
  _singleProductBaseUrl: process.env.REACT_APP_API_SINGLE_PRODUCT_BASE_URL,
  _apiDispatcher: process.env.REACT_APP_API_DISPATCHER,
  _salesforce: process.env.REACT_APP_SALESFORCE,
  _sectionsApi: process.env.REACT_APP_SECTIONS_API,
  _locale: "en-en",
  _localeId: "270",
  mLoadedData: null,
  mComponents: [],
  mProducts: [],
  mComponentViewPoints: [],
  mVariations: null,
  mHierarchical: null,
  mSelectedVariations: [],
  mSelectionHooks: [],
  mApplicationDataChangeHooks: [],
  mInitLoadedScripts: false,
  mLoadedScripts: {},
  mVariationJsAssetsCount: 0,
  mApplicationData: {},
  mSessionId: null,
  mVisitorId: null,
  mCurrentViewPoint: null,
  mCurrentComponentViewPoint: null,
  mExcludedViewPoints: ["AR","interior_panoramic","interior_driverView","interior_bedView", "Default"],
  mExcludedViewPointsForDownloadingImgs: ["AR","interior_panoramic", "Default"],
  mRemindSelection: false,
  mAttributes: [],
  mAttributesDependingOnMarket : ["Steeringwheel_position"],
  instance() {
    if (!window.dataContainer) {
      window.dataContainer = this;
    }
    if (!window.onScriptLoaded) {
      window.onScriptLoaded = (variationIframeKey) => {
        const variationId = variationIframeKey.split("_")[0];
        const object = document
          .getElementById(variationIframeKey)
          .contentWindow.instance();
        const copyOfObject = {};
        const keys = Object.keys(object);
        for (let idx = 0; idx < keys.length; idx += 1) {
          copyOfObject[keys[idx]] = object[keys[idx]];
        }
        window.dataContainer.appendScript(variationId, copyOfObject);
      };
    }
    return this;
  },
  appendScript(variationId, object) {
    this.mLoadedScripts[variationId] = object;
    if (this.mInitLoadedScripts) {
      this.initVariationScripts();
    }
  },
  initVariationScripts() {
    const jsKeys = Object.keys(this.mLoadedScripts);
    if (jsKeys.length === this.mVariationJsAssetsCount) {
      for (let idx = 0; idx < jsKeys.length; idx += 1) {
        this.mLoadedScripts[jsKeys[idx]].onInit(
          parseInt(jsKeys[idx], 10),
          this
        );
      }
    }
  },
  registerSelectionHook(callback) {
    if(typeof callback.uniqueKey !== "undefined"){
      if(this.mSelectionHooks.find((cb)=>cb.uniqueKey === callback.uniqueKey)){
        return;
      }
    }
    this.mSelectionHooks.push(callback);
  },
  registerApplicationDataChangeHook(callback) {
    this.mApplicationDataChangeHooks.push(callback);
  },
  find360AssetForSelectedVariations(){
    if(!this.mSelectedVariations){
      return "";
    }

    const componentGroup = this.findComponentGroupByName("Interior");
    const seatAttribute = this.getProductAttributes().find((attr) => attr.name === 'Seat');
    const cabHeightAttribute = this.getProductAttributes().find((attr) => attr.name === 'Cab_height');

    if(!seatAttribute || !cabHeightAttribute){
      return "";
    }

    const viewPoint = this.getAllProductViewPoints().find((pv)=>pv.name.includes("panoramic"));
    const componentViewPoint = componentGroup.component_view_points.find((cv)=>cv.product_view_point_id === viewPoint.id);

    // get trim levels as there we have stored 360
    const trimLevelsComponent = this.getAllComponents().find((c)=>c.name === "Int_trim_levels" && c.group_id===componentGroup.id);

    // find which variations have been selected
    let trimLevelVariation = null;
    let seatVariationAttributeId = null;
    let cabHeightVariationAttributeId = null;

    this.mSelectedVariations.forEach((vId) => {
      // find entire variation object
      const variation = this.findVariationBy(vId);

      //check if variation belongs to trim levels or seat
      if(variation?.component_id === trimLevelsComponent.id){
        trimLevelVariation = variation;
      }
    });

    if(trimLevelVariation == null){
      // no trim level then we can't do anything
      return "";
    }

    seatVariationAttributeId = this.mAttributes.find((attr)=>attr.attribute_id===seatAttribute.id);
    cabHeightVariationAttributeId = this.mAttributes.find((attr)=>attr.attribute_id===cabHeightAttribute.id);

    if(!seatVariationAttributeId || !cabHeightVariationAttributeId){
      return "";
    }

    const hasAttribute = (asset, attributes) => {
        return !!asset.attribute_values.find((av)=>{
          let finalStatus = true;
          
          for(let attribute of attributes){
            finalStatus &= av.attribute_id===attribute.attribute_id 
            && av.value===attribute.value;
          }

          return finalStatus;
        });
    };


    const marketLevelAttributes = this.mAttributes.filter((a)=>typeof a.market_based !== 'undefined');

    const asset = trimLevelVariation.assets.find((as)=>{
      return as.component_view_point_id===componentViewPoint.id 
        && (hasAttribute(as, [seatVariationAttributeId]) && hasAttribute(as, [cabHeightVariationAttributeId]))
        && hasAttribute(as, marketLevelAttributes) 
    });

    return asset ? asset.asset_url : "";
  },
  loadApplicationData(callback) {
    axios
      .get(
        this._applicationData + this._locale,
        {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
      )
      .then((r) => {
        if (r.data.success) {
          this.mApplicationData = r.data.data;
          if (callback) {
            callback();
          }
        }
      });
  },
  loadData(callback, locale, productID, cached_url) {
    if(locale.locale !== this._locale && locale.localeID !== this._localeId) {
      this._locale && (this._locale = locale.locale);
      this._localeId && (this._localeId = locale.localeID);
      this._mState = "passive";
    }
    this._productId = productID;
    if (this._mState !== "passive" && this.mLoadedData) {
      return;
    }
    this._mState = "fetching";

    const cacheDates = localStorage.getItem("cacheDates");
    const cacheDatesObj = JSON.parse(cacheDates) || {};

    let singleProductCachedUrl = `${this._singleProductBaseUrl}${productID}/data/${locale.locale}`;
    const product = this.mProducts.find((p) => p.id === productID);
    if(cacheDatesObj && product.published_version && cacheDatesObj[productID] !== product.published_version.cached_at){
      singleProductCachedUrl = `${this._singleProductBaseUrl}${productID}/data/${locale.locale}?key=${Math.random()}}`;
      cacheDatesObj[productID] = product.published_version.cached_at;
      localStorage.setItem("cacheDates", JSON.stringify(cacheDatesObj));
    };

    this.mSessionId = this.uuidv4();
    this.mVisitorId = this.uuidv4();
    axios.defaults.responseType = "json";
    axios
      .get(singleProductCachedUrl, {
        headers: {
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        },
      })
      .then((r) => {
        if (r.status !== 200) {
          this._mState = "passive";
          if (callback) {
            callback(null);
          }
        } else {
          this._mState = "fetched";
          this.mLoadedData = r.data.data;
          this.extractComponentsAndVariations();
          this.loadScriptAssetsForVariations(); //TODO: Method will be removed!
          this.loadApplicationData(() => {
            if (callback) {
              callback({
                instance: this,
                plainData: this.mLoadedData,
                variations: this.mVariations,
                components: this.mComponents,
              });
            }
          });
        }
        this.mCurrentViewPoint = this.getProductViewPoints()[0];
        this.mComponentViewPoints = this.retrieveComponentViewPoints();
        this.mCurrentComponentViewPoint = this.getCurrentComponentViewPoint();
        this.mRemindSelection = true;
        this.mRemindSelection && localStorage.setItem("currentSelections", this.generateUniqueHashForSelections());

        //automatic attributes selection based on market
        this.mAttributesDependingOnMarket.forEach((attr)=>{
          const productAttribute = this.getProductAttributes().find((attribute)=>attribute.name===attr);
          if(productAttribute){
            const defaultSelectionValue = this.sectionKey("Market.Settings", attr.toLowerCase());
            const attribute = this.findAttributeValueObjectByAttributeIdAndValue(productAttribute.id, defaultSelectionValue);

            if(attribute){
              this.markAttributeAsSelected({...attribute, market_based: true});
            }
          }
        });
      });
  },

  //TODO: Method below should be removed because it doesn't have any effect in the app since it only works if there are assets of type "js", and there are no such assets.
  loadScriptAssetsForVariations() {
    this.mVariations.forEach((v) => {
      if (v.assets.length > 0) {
        v.assets.forEach((vA) => {
          if (vA.asset_type === "js") {
            this.mVariationJsAssetsCount += 1;
            this.loadInstanceForVariation(v, vA.asset_url);
          }
        });
      }
    });
  },

  loadInstanceForVariation(variation, scriptUrl) {
    const iFrameHtml =
      `${"<html>" + "<head>" + "<script src='"}${scriptUrl}'>` +
      "</script>" +
      "</head>" +
      "</html>";
    const iFrameId = `${variation.id}_ifr_${Math.random()}`;
    // eslint-disable-next-line no-unused-vars
    const iframeSource = `<iframe onload='window.onScriptLoaded("${iFrameId}")' id="${iFrameId}" srcdoc="${iFrameHtml}"></iframe>`;
    if (document.getElementById("temp_iframes")) {
      setTimeout(() => {
        document
          .getElementById("temp_iframes")
          .insertAdjacentHTML("beforeend", iframeSource);
      }, 1500);
    } else {
      throw new DOMException("temp_iframes doesn't exist!");
    }
  },
  sectionKey(sectionName, key) {
    if (!this.mApplicationData || !this.mApplicationData.sections) {
      return `${key}`;
    }
    const section = this.mApplicationData.sections.find(
      (s) => s.section_name === sectionName
    );
    if (!key) {
      return section;
    }
    if (!section) {
      return `${key}`;
    }
    if (!Object.keys(section.key_values).includes(key)) {
      return `${key}`;
    }
    return section.key_values[key];
  },
  getAllVariations() {
    return this.mVariations;
  },
  getAllComponents() {
    const components = this.mLoadedData?.component_groups.map((el) => {
      return el.components
    })
    return components?.flat();
  },
  findVariationsByIds(ids){
    return this.mVariations.filter((v)=>ids.includes(v.id));
  },
  findAttributeValueObjectByAttributeIdAndValue(id, value){
    const filteredVariations = this.mVariations
      .filter((v)=> {
        if(!v){
          return false;
        }

        if(v.assets.length == 0){
          return false;
        }

        if(v.assets.find((asset)=>asset.attribute_values.length == 0)){
          return false;
        }

        return true;
      });

      for(const variation of filteredVariations){
        for(const asset of variation.assets){
          for(const attributeValue of asset.attribute_values){
            if(attributeValue && attributeValue.attribute_id === id && attributeValue.value === value){
              return attributeValue;
            }
          }
        }
      }

      return null;
  },
  extractComponentsAndVariations() {
    this.mComponents = [];
    this.mVariations = [];
    this.mLoadedData.component_groups.forEach((componentGroup) => {
      componentGroup.components.forEach((component) => {
        const tempComponent = {};
        Object.keys(component).forEach((k) => {
          tempComponent[k] = component[k];
        });
        delete tempComponent.available_variations;
        this.mComponents.push(tempComponent);
        component.available_variations.forEach((v) => {
          this.mVariations.push({
            ...v,
            component_group_name: componentGroup.name,
          });
          if (v.is_default) {
            this.toggleVariationSelection(v.id, null, true);
          }
        });
      });
    });
    const colorizerProduct = this.findProductByName("TestColorizer");
    colorizerProduct?.component_groups?.forEach((componentGroup) => {
      componentGroup.components.forEach((component) => {
        const tempComponent = {};
        Object.keys(component).forEach((k) => {
          tempComponent[k] = component[k];
        });
        delete tempComponent.available_variations;
        this.mComponents.push(tempComponent);
        component.available_variations.forEach((v) => {
          this.mVariations.push({
            ...v,
            component_group_name: componentGroup.name,
          });
          const colorIsDefaultForCurrentTruck = v.attribute_values?.some(attribute => attribute.value === this.mLoadedData.product_info.description);
          if (colorIsDefaultForCurrentTruck) {
            this.toggleVariationSelection(v.id);
          }
        });
      });
    });
    this.mComponents.sort((c1, c2) => c1.layer_order - c2.layer_order);
  },
  loadedData() {
    return this.mLoadedData;
  },
  getActualModelName() {
    return this.mLoadedData?.product_info.description.split("_")[0];
  },
  getCurrentModelId() {
    return this?.mLoadedData?.id;
  },
  resetData() {
    this.mLoadedData = null;
    this._mState = "passive";
    this.mLoadedData = null;
    this.mComponents = [];
    this.mVariations = null;
    this.mHierarchical = null;
    this.mSelectedVariations = [];
    this.mSelectionHooks = [];
    this.mApplicationDataChangeHooks = [];
    this.mInitLoadedScripts = false;
    this.mLoadedScripts = {};
    this.mVariationJsAssetsCount = 0;
  },
  findProductByName(name) {
    return this.mProducts?.find((product) => {
      return product.name === name;
    });
  },
  findComponentByName(name) {
    return this.getAllComponents().find((component) => component.name === name);
  },
  findParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(
        component.id,
        excludeDependencyVariationIds
      );
      if (parentVariations.length > 0) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },

  findSelectedVariantByComponentName(componentName) {
    return this.getSelectedVariations().find(
      (variation) => this.findComponentById(variation.component_id).name === componentName);
  },

  findSelectedVariantByComponentNameAndComponentGroup(componentName, componentGroupName) {
    const selectedVariation = this.getSelectedVariations().find((variation) =>
        variation && this.findComponentById(variation.component_id).name === componentName && variation.component_group_name === componentGroupName
    );
    return selectedVariation;
  },
  findFirstVariationByComponentNameAndCategory(name, category) {
    const component = this.getAllComponents().find(
      (component) => component.name === name
    );
    return component.available_variations.find(
      (variation) => variation.assets[0].attribute_values[1].value === category
    );
  },
  findComponentGroupByComponentId(id){
    return this.getComponentGroups() && this.getComponentGroups().find(componentGroup => componentGroup.id === this.findComponentById(id).group_id);
  },
  findComponentGroupByName(name){
    return this.getComponentGroups()?.find(componentGroup => componentGroup.name === name);
  },
  //TODO: This method should be removed since it's not in use!
  findNonAssetsParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(
        component.id,
        excludeDependencyVariationIds,
        false
      );
      if (
        parentVariations.length > 0 &&
        parentVariations.every(
          (v) =>
            v.assets.length === 0 ||
            (!v.is_default && v.assets.some((a) => a.asset_type === "png"))
        )
      ) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },
  findVariationType(componentName) {
    componentName = componentName.toLowerCase();
    const key = `component_${componentName}_subaccordion_type`;
    return this.mApplicationData.sections[0].key_values[key];
  },
  findVariationBy(id) {
    return this.mVariations?.find((v) => v.id === id);
  },
  findVariationByOriginalKey(key){
    return this.mVariations?.find((v) => v.original_key === key);
  },
  findComponentById(id) {
    if (id) return this.mComponents.find((c) => c.id === id);
    return id;
  },
  findComponentVariations(id) {
    return this?.mVariations?.filter((v) => v.component_id === id).filter(v=>v.assets.length>0);
  },
  findParentVariationsForComponent(
    id,
    excludeDependencyVariationIds,
    excludeWithoutAssets = true
  ) {
    const componentVariations = this.findComponentVariations(id);
    const variations = [];
    componentVariations.forEach((v) => {
      if (excludeWithoutAssets && v.assets.length === 0) {
        return;
      }
      if (v.dependencies.length === 0) {
        variations.push(v);
      }
      if (
        excludeDependencyVariationIds &&
        v.dependencies.find((tempV) =>
          excludeDependencyVariationIds.includes(tempV.depends_on_variation_id)
        )
      ) {
        variations.push(v);
      }
    });
    return variations;
  },
  findChildrenForVariation(id) {
    const childrenVariations = [];
    this.mVariations.forEach((v) => {
      if (v.dependencies.find((d) => d.depends_on_variation_id === id)) {
        childrenVariations.push(v);
      }
    });
    return childrenVariations;
  },
  findChildrenComponentsForVariation(variationId) {
    const variations = this.findChildrenForVariation(variationId);
    if (variations.length === 0) {
      return [];
    }
    const componentIds = [...new Set(variations.map((v) => v.component_id))];
    if (!componentIds) {
      return [];
    }
    const components = [];
    componentIds.forEach((cId) => components.push(this.findComponentById(cId)));
    return components;
  },
  filterByCategory(items, category) {
    return items.filter(
      (item) => category === item.assets[0].attribute_values[1].value
    );
  },
  isVariationSelected(id) {
    return this.mSelectedVariations.includes(id);
  },
  toggleVariationSelection(id, action, ignoreHooks = false) {
    const oldSelectionState = [];
    if (!ignoreHooks) {
      this.mSelectedVariations.forEach((v) => oldSelectionState.push(v));
    }
    const index = this.mSelectedVariations.indexOf(id);
    const variation = this.findVariationBy(id);
    const component = this.findComponentById(variation.component_id);
    let event;
    if (action !== "add" && (index !== -1 || action === "delete")) {
      if (index > -1) {
        if (
          !ignoreHooks &&
          component.required_choice &&
          this.isVariationSelected(id)
        ) {
          // if the component has only two variations switch to the other variations
          const componentVariations = this.mVariations.filter(
            (v) => v.component_id === component.id && v.id !== id
          );
          if (componentVariations.length === 1) {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "unselect");
            });
            this.mSelectedVariations.splice(index, 1);
            if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
              this.mLoadedScripts[variation.id].onUnselect(variation, this);
            }
            this.toggleVariationSelection(componentVariations[0].id);
          } else {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "select");
            });
            this.logEvent("variation", variation.id, {
              product_id: this.mLoadedData.id
            });
          }
          localStorage.setItem("currentSelections", this.generateUniqueHashForSelections());
          return;
        }
        this.mSelectedVariations.splice(index, 1);
        event = "unselect";
        if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
          this.mLoadedScripts[variation.id].onUnselect(variation, this);
        }
      }
      if (!component.multiple && !ignoreHooks) {
        this.clearSameComponentSelection(id);
      }
      this.removeNestedVariationSelection(id);
      localStorage.setItem("currentSelections", this.generateUniqueHashForSelections());
    } else if (!action || action === "add") {
      this.mSelectedVariations.push(id);
      if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
        this.mLoadedScripts[variation.id].onSelect(variation, this);
      }
      event = "select";
      if (!component.multiple) {
        this.clearSameComponentSelection(id);
        this.selectNestedDefaultVariation(id);
      }
    }
    if (!ignoreHooks) {
      const newSelectionState = [];
      this.mSelectedVariations.forEach((v) => newSelectionState.push(v));

      this.mSelectionHooks.forEach((cb) => {
        cb([id], event);
        cb(newSelectionState, "selection_change");
      });
      this.logEvent("variation", variation.id, {
        product_id: this.mLoadedData.id
      });
      const jsKeys = Object.keys(this.mLoadedScripts);
      if (jsKeys.length > 0) {
        for (let idx = 0; idx < jsKeys.length; idx += 1) {
          this.mLoadedScripts[jsKeys[idx]].onSelectionChanged(
            id,
            oldSelectionState,
            newSelectionState,
            this
          );
        }
      }
    }
    localStorage.setItem("currentSelections", this.generateUniqueHashForSelections());
    const attribute = this.findAttributeFromAssets(variation);
    if(attribute) this.markAttributeAsSelected(attribute);
  },
  isVariationDependentOn(variation, onVariation) {
    return variation.dependencies.some(
      (v) => v.depends_on_variation_id === onVariation.id
    );
  },
  isSelectedVariationFromThisProduct(productName, selectedVariation) {
    const product = this.findProductByName(productName);
    return product?.component_groups?.map(cg => cg.name).includes(selectedVariation.component_group_name);
  },
  clearSameComponentSelection(id) {
    const selectedVariation = this.findVariationBy(id);
    const deletedVariationIds = [];
    this.mSelectedVariations.forEach((variationID, idx) => {
      if(variationID !== id) {
        const variation = this.findVariationBy(variationID);
        const isSelectedVariationFromColorizer = this.isSelectedVariationFromThisProduct("TestColorizer", selectedVariation);
        //the first "if" below does this:
        //if selectedVariation belongs to Colorizer product, do not check if we have another selected variation from the same Component
        //but rather check if we have another selected variation from the same Product(Colorizer)
        if (isSelectedVariationFromColorizer) {
          if(this.isSelectedVariationFromThisProduct("TestColorizer", variation)) {
            if(deletedVariationIds.length > 0) this.mSelectedVariations.splice(idx, 1);
            this.mSelectedVariations.splice(idx - deletedVariationIds.length, 1);
            deletedVariationIds.push(variation.id);
          }
        }
        else if (
          selectedVariation.component_id === variation?.component_id &&
          !this.isVariationDependentOn(selectedVariation, variation)
        ) {
          if(deletedVariationIds.length > 0) this.mSelectedVariations.splice(idx, 1);
          this.mSelectedVariations.splice(idx - deletedVariationIds.length, 1);
          deletedVariationIds.push(variation.id);
        }
      }
    })
    deletedVariationIds.forEach((v) =>
      this.removeNestedVariationSelection(v, id)
    );
  },
  removeNestedVariationSelection(id, excludeVariation = 0) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);
    if (childrenComponents) {
      childrenComponents.forEach((c) =>
        this.mVariations
          .filter((v) => v.component_id === c.id)
          .forEach((variationToDelete) => {
            if (
              this.mSelectedVariations.includes(variationToDelete.id) &&
              excludeVariation !== variationToDelete.id
            ) {
              this.toggleVariationSelection(
                variationToDelete.id,
                "delete",
                true
              );
            }
          })
      );
    }
  },
  selectNestedDefaultVariation(id) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);
    if (childrenComponents) {
      childrenComponents.forEach((c) => {
        if (c.required_choice) {
          const componentVariations = this.findComponentVariations(c.id);
          componentVariations.forEach((cv) => {
            if (cv.is_default && !this.isVariationSelected(cv.id)) {
              this.toggleVariationSelection(cv.id, "add", true);
            }
          });
        }
      });
    }
  },
  getSelectedVariations() {
    const selectedVariations = [];
    this.mSelectedVariations.forEach((vId) =>
      selectedVariations.push(this.findVariationBy(vId))
    );
    return selectedVariations;
  },
  isVariationUnselectable(id) {
    const variation = this.findVariationBy(id);
    if (!variation) {
      return true;
    }
    const component = this.findComponentById(variation.component_id);
    if (!component) {
      return true;
    }
    if (
      variation.is_default &&
      component.required_choice &&
      this.mVariations.filter((v) => v.component_id === component.id).length < 2
    ) {
      return false;
    }
    return true;
  },

  generateUniqueHashForSelections() {
    const content = {
      selections: [],
      productId: this._productId,
    };
    this.mSelectedVariations.forEach((v) => content.selections.push(v));
    return btoa(JSON.stringify(content));
  },

  loadSelectionFromHash(hash, url) {
    try {
      const content = JSON.parse(window.atob(hash));
      if(content.productId < 154 && url !== undefined) {
        window.location.href = url;
      }
      this.mSelectedVariations = [];
      this._productId = content.productId;
      content.selections.forEach((s) => {
        this.mSelectedVariations.push(s)
      });
      this.mSelectionHooks.forEach((cb) =>
        cb(this.mSelectedVariations, "select")
      );

      localStorage.setItem("currentSelections", this.generateUniqueHashForSelections());
    } catch (e) {
      console.error(e);
    }
  },

  uuidv4() {
    // eslint-disable-next-line no-mixed-operators
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  // eslint-disable-next-line no-dupe-keys
  logEvent(event, variation, tagList) {
    // disabled temporary. Needs to be enabled later.
    const disable = true;
    if(disable){
      return;
    }
    if(!this.mSessionId && !this.mVisitorId) {
      this.mSessionId = this.uuidv4();
      this.mVisitorId = this.uuidv4();
    }
    axios.post(
      "https://volvo-trucks-01-one-insights.onecx.cloud/api/logs/analytics/analyticTrack",
      {
        e: event,
        e_v: variation,
        a: this._applicationId,
        s: this.mSessionId,
        v: this.mVisitorId,
        tags: tagList,
      }
    );
  },
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  },
  getAllProductViewPoints() {
    return this.mLoadedData?.product_view_points;
  },
  getProductViewPoints() {
    return this.mLoadedData?.product_view_points.filter(
      (viewPoint) => !this.mExcludedViewPoints.includes(viewPoint.name)
    );
  },
  getProductViewPointsForDownloadingImgs() {
    return this.mLoadedData?.product_view_points.filter(
      (viewPoint) => !this.mExcludedViewPointsForDownloadingImgs.includes(viewPoint.name)
    );
  },
  getCurrentPointOfViewName() {
    return this.mCurrentViewPoint?.name ?? "";
  },
  // TODO: This method is not in use and should be removed
  getCurrentPointOfViewIndex() {
   if (this.mCurrentViewPoint === null || !this.getCurrentPointOfViewName()) {
    return 0;
   }

   let idx = 0;
    this.getProductViewPoints().find((vP, index) =>  {
      if (vP.name === this.getCurrentPointOfViewName()) {
        idx = index;
      }
    });

   return idx;
  },

  setCurrentViewPoint(viewPoint) {
    const viewPoints = this.getProductViewPoints();
    if(!viewPoint && !viewPoints?.includes(viewPoint)) return;
    this.mCurrentViewPoint = viewPoint;
  },

  clearUniqueHashFromStorage(key) {
    localStorage.removeItem(key);
  },

  getComponentByComponentGroupsId(componentGroupID) {
    const components = [];
    this.mComponents.forEach((component) => {
      if (component.group_id === componentGroupID) {
        components.push(component);
      }
    });
    return components;
  },

  getComponentGroupFromComponentID(componentID) {
    const component = this.findComponentById(componentID);
    return this.findComponentGroupByID(component.group_id);
  },

  getComponentGroups() {
    return this.mLoadedData?.component_groups;
  },

  getAllComponentGroups() {
    const colorizerProduct = this.findProductByName("TestColorizer");
    const allExistingComponentGroups = this.mLoadedData?.component_groups.concat(colorizerProduct?.component_groups);
    return allExistingComponentGroups
  },

  findComponentGroupByID(id) {
    return this.getAllComponentGroups().find(cg => cg.id === id)
  },

  getProductAttributes() {
    const componentGroups = this.mLoadedData?.component_groups;
    let attributes = [];
    componentGroups?.map((componentGroup) => {
      attributes = componentGroup.attributes;
    });
    return attributes;
  },

  //TODO: Method not in use
  loadImageMerge(data, loadMergedImage = false) {
    if (loadMergedImage) {
      axios.defaults.headers["Accept"] = "image/png";
      axios.defaults.responseType = "arraybuffer";
    }
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_ONE_CONFIGURATOR_DATA, data, {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  },
  //TODO: Method not in use
  fetchImagesForAllViewPoints() {
    const result = new Promise((resolve, reject) => {
      Promise.all(
        this.getProductViewPoints().map(
          (item) =>
            new Promise((resolve) =>
              resolve(this.fetchMergedImageForSelection(item.id))
            )
        )
      ).then((data) => {
        resolve(data);
      });
    });
    return result;
  },

   //TODO: Method not in use
  fetchMergedImageForSelection(currentViewPoint) {
    const data = {
      product_id: this._productId,
      locale: this._locale,
      filters: {
        attributes: this.mAttributes,
        product_view_point_id: currentViewPoint || this.mCurrentViewPoint?.id,
        variation_ids: this.mSelectedVariations,
      },
    };
    if (currentViewPoint) {
      const result = new Promise((resolve, reject) => {
        this.loadImageMerge(data, true).then((el) => {
          resolve(el);
        });
      });
      return result;
    } else {
      return this.loadImageMerge(data, true);
    }
  },
  //TODO: Method not in use
  fetchImageLayersForSelection() {
    const data = {
      product_id: this._productId,
      locale: this._locale,
      filters: {
        attributes: this.mAttributes,
        product_view_point_id: this.mCurrentViewPoint.id,
        variation_ids: this.mSelectedVariations,
      },
    };
    const result = new Promise((resolve, reject) => {
      this.loadImageMerge(data).then((el) => {
        let imageLayers = el.product_components.map((component) => {
          return component.variations[0].asset_url;
        });
        resolve(imageLayers);
      });
    });
    return result;
  },
  fetchAllAssetsOfProduct() {
    const dataAssets = [];
    if (this.getAllComponents()) {
      this.getAllComponents().map((component) => {
        component.available_variations.map((variation) => {
          if (variation.assets.length) {
            variation.assets.map((element) => {
              element.attribute_values.map((attributeObj) => {
                dataAssets.push(attributeObj);
              });
            });
          }
        });
      });
    }
    return dataAssets;
  },
  findAttributeFromAssets(variation){
    const filteredAttribute = this.filteredAttributes(variation);
    const attribute = this.fetchAllAssetsOfProduct().find((el) => {
      return el.value.toLowerCase() === filteredAttribute?.toLowerCase()
    });
    return attribute;
  },
  filteredAttributes(variation) {
    const component = this.findComponentById(variation?.component_id);
    //if component has hidden on it don consider
    if(!component || component.name.includes("hidden_") || (variation.assets && variation.assets.length == 0)){
      return "";
    }

    let variationKey = variation.original_key;
    const engineAttributes = ["euro6", "euro5", "electric", "lng", "cng", "euro3"];
    for(const component of this.mComponents){
      if(component.id === variation.component_id) {
        if(component.name === "Cabs") {
          variationKey = variationKey.split("_")[1].toUpperCase();
          break;
        } else if (component.name === "Chassis") {
          if(variationKey.includes("med")) {
            variationKey = "Medium";
            break;
          }
          variationKey = variationKey?.split("_").pop();
          break;
        } else if (component.name === "Engines") {
          const attributeByVariation = variationKey.split("_").find((v)=>engineAttributes.includes(v));
          if(attributeByVariation){
            if(attributeByVariation === "euro3"){
              variationKey = "euro5";
            }
            else {
              variationKey = attributeByVariation;
            }
            break;
          }
        }
      }
    }
    return variationKey
  },
  markAttributeAsSelected(incomingAttributeValue) {
    const existingAttribute = this.mAttributes.find((el) => el?.attribute_id === incomingAttributeValue?.attribute_id);
    if(existingAttribute){
      this.mAttributes.filter((value, key) => {
        if(value?.attribute_id === incomingAttributeValue?.attribute_id && value.id !== incomingAttributeValue.id) {
          this.mAttributes[key] = incomingAttributeValue;
        }
      });
    } else {
      this.mAttributes.push(incomingAttributeValue);
    }
  },

  analyticTrackEvent(eventType, customType, body, allChoices) {
    if (
      "RIAnalytics" in window &&
      typeof window["RIAnalytics"].trackEvent === "function"
    ) {
      window["RIAnalytics"].trackEvent(
        eventType,
        customType,
        body,
        allChoices && allChoices
      );
    }
  },

  generateConfigurator(viewPoint) {
    const selectedVariations = this.getSelectedVariations();
    const assetsLink  = [];
    const componentViewPoints = this.getCurrentComponentViewPoint(viewPoint);
      selectedVariations.forEach((variation) => {
        componentViewPoints.forEach((current) => {
          let assetFromViewpoint = variation.assets.find((el) => el.component_view_point_id === current?.id);
          if(assetFromViewpoint && assetFromViewpoint.attribute_values.length) {
            this.mAttributes.forEach((attr) => {
              variation.assets.map((asset) => {
                const filteredAttributes = asset.attribute_values.filter((assetAttr) => assetAttr.id === attr.id)
                if(filteredAttributes.length > 0 && asset.component_view_point_id === current?.id) {
                  assetFromViewpoint = asset;
                }
              })
            })
          }
          assetsLink.push(assetFromViewpoint?.asset_url);
        })
      })
    return assetsLink;
  },
  retrieveComponentViewPoints() {
    this.mLoadedData.component_groups?.map((componentGroup) => {
      this.mComponentViewPoints.push(componentGroup?.component_view_points);
    });
    return this.mComponentViewPoints;
  },
  getCurrentComponentViewPoint(viewpoint) {
    const allComponentViewPoints = this.mComponentViewPoints;
    const currentComponentViewPoint = allComponentViewPoints.map((el) =>
      el.find(
        (viewPoint) =>
          viewPoint.product_view_point_id ===
          (viewpoint ? viewpoint.id : this.mCurrentViewPoint.id)
      )
    );
    return currentComponentViewPoint;
  },

  base64Resize(sourceBase64, scale) {
    const _scale = scale;
    const img = document.createElement("img");
    img.setAttribute("src", sourceBase64);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      const ctx = canvas.getContext("2d");
      const maxW = img.width * _scale;
      const maxH = img.height * _scale;

      const iw = img.width;
      const ih = img.height;
      const scl = Math.min((maxW / iw), (maxH / ih));
      const iwScaled = iw * scl;
      const ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx?.drawImage(img, 0, 0, iwScaled, ihScaled);

      return canvas.toDataURL("image/png", scl);
    };
    return img.onload();
  },

  //Send configuration and contact info to seller
  async sendContactInfoAndConfigurationToSeller(
    userData,
    serviceActiveCampaign,
    locale,
    linkToConfig,
    linkToImage,
  ) {
    try {
      // Send Truck Configuration data to Adobe
      if (serviceActiveCampaign !== "true") {
        const data = {
          company: userData.company,
          configUrl: linkToConfig,
          configImgUrl: linkToImage,
          dealerAddressLine1: userData.chosen_dealer.addressLine1,
          dealerAddressLine2: userData.chosen_dealer.addressLine2,
          dealerAddressLine3: userData.chosen_dealer.addressLine3,
          dealerCity: userData.chosen_dealer.city,
          dealerCountryName: userData.chosen_dealer.countryname,
          dealerName: userData.chosen_dealer.name,
          dealerPostcode: userData.chosen_dealer.postcode,
          email: userData.email,
          market: locale,
          message: userData.message,
          name: userData.name,
          phoneNumber: userData.phone,
          customerZipCode: userData.zip,
          customerCountry: userData.country,
          customerCity: userData.city,
          segment: userData.segment,
          marketingConsent: userData.marketingCheckbox
          // terms: userData.pronounTitle,
        };
        return await this.sendAdobeCampaign(data);
        // 'message is sent'
      } else {
        // Send Truck Configuration data to Active Compaign
        let data = {
          aanhef: userData.aanhef,
          company: "",
          configuration: linkToConfig,
          contact: userData.sellerText,
          customer_acct_name: userData.company,
          dealerAddressLine1: userData.chosen_dealer.addressLine1,
          dealerAddressLine2: userData.chosen_dealer.addressLine2,
          dealerAddressLine3: userData.chosen_dealer.addressLine3,
          dealerCity: userData.chosen_dealer.city,
          dealerCountryName: userData.chosen_dealer.countryname,
          dealerName: userData.chosen_dealer.name,
          dealerPostcode: userData.chosen_dealer.postcode,
          email: userData.email,
          establishment: userData.establishment,
          "field[%truck_builder_url%]": linkToConfig,
          "field[%truck_builder_image_url%]": linkToImage,
          "field[%GEKOZEN_DEALER_TRUCK_BUILDER%, 0]": userData.chosen_dealer.name,
          "field[%aanhef%]": userData.aanhef,
          "field[%optin_contact_opnemen_door_verkoper%]": `${userData.sellerText ? this.sectionKey("ContactDealerForm", "contact_dealer_optin_record_by_seller_text") : ""}`,
          "field[%optin_gelezen_en_akkoord_privacy_statement%]": `${userData.privacyText ? this.sectionKey("ContactDealerForm", "contact_dealer_optin_read_and_agree_privacy_text") : ""}`,
          "field[%optin_ontvangen_volvo_trucksnieuws%]": `${userData.newsText ? this.sectionKey("ContactDealerForm", "contact_dealer_optin_volvo_trucks_news_text") : ""}`,
          "field[%vestigingsplaats_bedrijf%]]": userData.establishment,
          first_name: userData.name,
          last_name: userData.lastname,
          locale: locale,
          name: "",
          phone: userData.phone,
          phoneNumber: "",
          privacy: userData.privacyText,
          salutation: `${userData.aanhef === "heer" ? "mr" : "mw"}`,
          serviceActiveCampaign: !!serviceActiveCampaign,
          vestigingsplaats_bedrijf: userData.establishment,
          volvonews: userData.newsText,
          "tags": "Configuratie Truck Builder dealer",
        };
      let respSecond = await this.sendActiveCampaign(data, "add");
      const result = xmlToJs.xml2js(respSecond.data.data, {compact: true, spaces: 4});
      if (result.subscriber_insert_post.result_code._text === "0") {
        const contactId = result.subscriber_insert_post.row.id._text;
        data["id"] = contactId;
        data["p[97]"] = 97;
        data["tags"] = "Configuratie Truck Builder dealer",
        data["status[97]"] = 1;
        data["overwrite"] = 0;
      }
      respSecond = await this.sendActiveCampaign(data, "edit");
      return respSecond; // 'message is sent'
      }
    } catch (err) {
      return err;
    }
  },

  async sendTruckConfigurationToCostumer(
    configuration,
    email,
    serviceActiveCampaign,
    linkToImage,
    // volvonews, //news checkbox
    marketingConsent,
    locale
  ) {
    try {
      // Send Truck Configuration data to Adobe    
      if (serviceActiveCampaign !== "true") {
          const data = {
              configUrl: configuration,
              email: email,
              // privacy: "",
              // serviceActiveCampaign: serviceActiveCampaign,
              configImgUrl: linkToImage,
              // volvonews: `${volvonews ? this.sectionKey("ContactDealerForm", "contact_dealer_optin_volvo_trucks_news_text") : ""}`,
              marketingConsent: marketingConsent,
              market: locale,
          };
          await this.sendEmailToCostumer({email, configuration, linkToImage, locale}); // Send data to Adobe
          return await this.sendAdobeCampaign(data);
      } else {
          // Send Truck Configuration data to Active Compaign
          let data = {
            email: email,
            privacy: "",
            serviceActiveCampaign: serviceActiveCampaign,
            // volvonews: `${volvonews ? this.sectionKey("ContactDealerForm", "contact_dealer_optin_volvo_trucks_news_text") : ""}`,
            market: locale,
            "field[%truck_builder_url%]": configuration,
            "field[%truck_builder_image_url%]": linkToImage,
            "tags": "Configuratie Truck Builder e-mail"
          };
          let respSecond = await this.sendActiveCampaign(data, "add");
          const result = xmlToJs.xml2js(respSecond.data.data, {compact: true, spaces: 4});
          if (result.subscriber_insert_post.result_code._text === "0") {
            const contactId = result.subscriber_insert_post.row.id._text;
            data["id"] = contactId;
            data["p[97]"]= 97;
            data["tags"] = "Configuratie Truck Builder e-mail",
            data["status[97]"] = 1;
            data["overwrite"] = 0;
          }
          respSecond = await this.sendActiveCampaign(data, "edit");
          return respSecond; // 'message is sent'
          }
        } catch (err) {
          return err;
        }
  },

  // Send truck configuration data to Adobe Campaign service
  async sendAdobeCampaign(data) {
    return await axios.post(
      `${this._apiDispatcher}send-configuration-to-seller/`,
      data,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        },
      }
    );
  },
  
  // Send configuration to costumer
  async sendEmailToCostumer(data) {
    return await axios.post(
      `${this._apiDispatcher}truck-configuration-email/`,
      {
        email: data.email,
        ctx: {
          configImageUrl: data.linkToImage,
          configUrl: data.configuration,
          AC_language: data.locale,
          market: data.locale,
        },
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        },

      }
    );
  },

  //Send truck configuration data to Active Campaign service
  async sendActiveCampaign(data, method) {
    return await axios.post(
      `${this._apiDispatcher}send-configuration-to-seller-active-campaign/${method}`,
        data,
        {
          headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
    );
  },

  collectVariationAssets(variation, attributes)
  {
    return variation?.assets.filter((asset) => {
      attributes.forEach((attribute) => {
        const variationAttribute = asset.attribute_values?.find((variation) =>  variation.attribute_id === attribute.attribute_id);
        if(!variationAttribute) {
          return true;
        }
        if(variationAttribute.id !== attribute.value_id) {
          return false
        }
      })

      return !!asset.attribute_values;
    })
  },
  collectVariations(component, attributes, filterVariationIds, product) {
    let filteredVariations = component.available_variations.filter((variation) => filterVariationIds.includes(variation.id));
    if(!filteredVariations) {
      filteredVariations = component.available_variations.filter((variation) => variation.is_default === true)
    }

    if(!filteredVariations){
      return [];
    }

    if(attributes.length > 0) {
      filteredVariations = filteredVariations.filter((variation) => {
        return this.collectVariationAssets(variation, attributes)?.length > 0
      });
    }

    return filteredVariations;
  },
  //TODO: Method below not in use, make sure we won't need it, and remove it
  getVariationsWhichDontHaveAssets(variationIds){
    const variationsToIgnore = [];

    const copyVariationIds = JSON.parse(JSON.stringify(variationIds));
    //make sure we copy the current selection
    const currentSelection = JSON.parse(JSON.stringify(this.mSelectedVariations));
    let currentSelectedIndex = currentSelection.findIndex((vid)=>variationIds.includes(vid));

    const componentId = this.findVariationBy(variationIds[0]).component_id;

    for(const vId of copyVariationIds){
      if(currentSelectedIndex == -1){
        currentSelection.push(vId);
        currentSelectedIndex = currentSelection.findIndex((vid)=>vid === vId);
      }else{
        currentSelection[currentSelectedIndex] = vId;
      }

      //it checks only for one component
      let components = this.getProductComponents(this.mLoadedData, undefined, currentSelection);

      if(components == null || !components.find((c)=>c && c.id==componentId)){
        variationsToIgnore.push(vId);
        continue;
      }

      components = components.filter((c) => c != null);
      
      const componentVariations = components.find((c)=>c.id==componentId).variations;

      if(componentVariations.length === 0 || componentVariations[0] == null){
          variationsToIgnore.push(vId);
          continue;
      }

      if(!componentVariations[0].find((v)=>v.id==vId)){
        variationsToIgnore.push(vId);
      }
    }

    return variationsToIgnore;
  },
  //TODO: This method is only called in a method that is not in use, so this should be removed too! (getAllComponents exists)
  getProductComponents(productData, viewpoint, customSelection = undefined) {
    const componentGroups = productData?.component_groups;
    let filterVariationIds = this.mSelectedVariations;
    if(customSelection){
      filterVariationIds = customSelection;
    }

    const productViewPoint = viewpoint ? viewpoint : productData?.product_view_points.find((viewpoint) => viewpoint.id === this.mCurrentViewPoint.id);

    const componentGroupAttributeIds = componentGroups?.flatMap((component) => component.attributes.map((attribute) => attribute.id));

    const attributeFilters = this.mAttributes.filter((attribute) => {
      return componentGroupAttributeIds.includes(attribute?.attribute_id);
    });

    const components = componentGroups?.flatMap((component) => component.components);
    const componentViewPoints = componentGroups?.map((component) => component.component_view_points.find((viewpoint) => viewpoint.product_view_point_id === productViewPoint.id)).map((componentViewPoint) => componentViewPoint?.id);

    return components?.flatMap((component) => {
      let filteredVariations = this.collectVariations(component, attributeFilters, filterVariationIds, productData);

      filteredVariations = filteredVariations.map((variation) => {
        let variationAssets = variation.assets.filter((asset) => componentViewPoints.includes(asset.component_view_point_id));

        const variationAsset = variationAssets?.find((asset) => {
            return !attributeFilters.map((attribute) => {
              let value = asset?.attribute_values?.find((val) => {
                return val.attribute_id === attribute.attribute_id;
              })
              return !value || value.id === attribute.id
            }).includes(false);
        });

        return variationAsset ? [{
          'id': variation.id,
          'price': variation.price,
          'is_default': variation.is_default,
          'preview_image_url': variationAsset.preview_image_url,
          'asset_url': variationAsset.asset_url,
          'asset_type': variationAsset.asset_type
        }] : null;
      });

      return filteredVariations.length > 0 ? {
        'id': component.id,
        'name': component.name,
        'layer_order': component.layer_order,
        'component_group_id': component.group_id,
        'variations': filteredVariations,
      } : null;
    })
  },

  filterSameComponentVariations(array) {
    const dependencyComponentIDs = array.map(dep => this.findVariationBy(dep.depends_on_variation_id).component_id);
    return dependencyComponentIDs.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  },
  
  filterChassisByEngineType(variationChassisIds){
    const variationsToFilter = [];
    const variationsToIgnore = [];
    let copyVariationIds = JSON.parse(JSON.stringify(variationChassisIds));
    //make sure we copy the current selection
    const currentSelection = JSON.parse(JSON.stringify(this.mAttributes));
    const engineAttributes = ["Euro6", "Euro5", "Electric", "LNG", "CNG"];

    let componentId = this.findVariationBy(variationChassisIds[0]).component_id;

    for(const vId of copyVariationIds){

      //it checks only for one component
      let singleComponent = this.findComponentById(componentId);

      if(singleComponent == null){
        variationsToIgnore.push(vId);
        continue;
      }

      const componentVariations = this.findComponentVariations(singleComponent.id);

      for(const variant of componentVariations){
        for(const asset of variant.assets){
          for(const attri of asset.attribute_values){
            const engine = engineAttributes.find((engine) => {
              return engine === attri.value
            })
            if(engine && engine === currentSelection.find(attribute => engineAttributes.includes(attribute.value))?.value){
              const exists = variationsToFilter.find((exists) => {
                return exists === asset.variation_id
              })
              if(exists === undefined || exists != asset.variation_id){
                variationsToFilter.push(asset.variation_id);
              }
            }
          }
        }
      }
    }

    for(const variation of variationsToFilter){
      const index = copyVariationIds.indexOf(variation);
      copyVariationIds.splice(index, 1);
    }

    return copyVariationIds;
  },
  filterChassiTypeByEngineType(variationChassiTypeIds, variationChassisIds){
    const variationsToFilter = [];
    const variationsToIgnore = [];
    const copyVariationIds = JSON.parse(JSON.stringify(variationChassisIds));
    //make sure we copy the current selection
    const currentSelection = JSON.parse(JSON.stringify(this.mAttributes));
    const engineAttributes = ["Euro6", "Euro5", "Electric", "LNG", "CNG"];

    let componentId = this.findVariationBy(variationChassisIds[0])?.component_id;

    for(const vId of copyVariationIds){
      let singleComponent = this.findComponentById(componentId);

      if(singleComponent == null){
        variationsToIgnore.push(vId);
        continue;
      }

      const componentVariations = this.findComponentVariations(singleComponent.id);
      for(const variant of componentVariations){
        for(const asset of variant.assets){
          for(const attri of asset.attribute_values){
            const engine = engineAttributes.find((engine) => {
              return engine === attri.value
            })
            if(engine && engine === currentSelection.find(attribute => engineAttributes.includes(attribute.value))?.value){
              const exists = variationsToFilter.find((exists) => {
                return exists === asset.variation_id
              })
              if(exists === undefined || exists != asset.variation_id){
                variationsToFilter.push(asset.variation_id);
              }
            }
          }
        }
      }
    }


    const copyChassiTypeIds = JSON.parse(JSON.stringify(variationChassiTypeIds));


    for(const variation of variationsToFilter){
      const name = this.findVariationBy(variation);
      for(var i = 0; i < copyChassiTypeIds.length; i++){
        const type = this.findVariationBy(copyChassiTypeIds[i]);
        if(name.key.includes("rig") && type.key === "rigid"){
          copyChassiTypeIds.splice(i, 1);
        }
        else if(name.key.includes("tra") && type.key === "tractor"){
          copyChassiTypeIds.splice(i, 1);
        }
      }
    }

    return copyChassiTypeIds;
  },

  sendDataToAdobeDataLayer(event, stepNumber, stepName, action,  truckbuilderConfiguration, fileFormat) {
    window.adobeDataLayer = window.adobeDataLayer || [];

    const destination = this.sectionKey("ContactDealer.Settings", "salesforce") === "true" ? "Salesforce" : this.sectionKey("ContactDealer.Settings", "active_campaign") === "true" ? "Active Campaign" : "Adobe Campaign"
    const selectedOptions = [];

    if(truckbuilderConfiguration) {
      Object.entries(truckbuilderConfiguration)?.forEach(config => {
        selectedOptions.push({
          "attribute": config[0],
          "value": config[1]
        })
      });
    }

    adobeDataLayer.push({
      event: event,
      _volvo: {
        universal: {
          web: {
            application: {
              name: "Volvo Truck Builder",
              stepNumber: stepNumber,
              stepName: stepName,
              action: action,
              selectedOptions: selectedOptions.length ? selectedOptions : undefined,
              applicationInteraction: {
                value: 1
              }
            }
          }
        }
      },
      web: {
        webInteraction: {
          type: "other"
        }
      }
    })

    if(action === "Download Configuration") {
      adobeDataLayer.push({
        event: "fileDownload",
        _volvo: {
          universal: {
            web: {
              file: {
                parent: "Volvo Truck Builder",
                url: "",
                displayText: "Download Truck Builder Configuration",
                name: "Truck Builder Configuration Result",
                format: fileFormat,
                category: "Truck Builder Configuration",
                fileDownload: {
                  value: 1
                }
              }
            }
          }
        },
        "web": {
          "webInteraction": {
            "type": "download"
          }
        }
      })
    } else if(action === "Form Start") {
      adobeDataLayer.push({
        event: "formView",
        _volvo: {
          universal: {
            web: {
              form: {
                type: "contact",
                name: "Truck Builder Contact Form",
                destination: destination,
                formView: {
                  value: 1
                }
              }
            }
          }
        },
        web: {
          webInteraction: {
            type: 'other'
          }
        }
      })
    } else if (action === "Form Submit") {
      window.adobeDataLayer.push({
        event: "formSubmitSuccess",
        _volvo: {
          universal: {
            web: {
              form: {
                type: "contact",
                name: "Truck Builder Contact Form",
                destination: destination,
                formSubmitSuccess: {
                  value: 1
                }
              }
            }
          }
        },
        web: {
          webInteraction: {
            type: "other"
          }
        }
      });      
    }  
  },
  
  getSelectedVariationsForPreviousComponentGroup(previousComponentGroup) {
    const components = this.getComponentByComponentGroupsId(previousComponentGroup.id);
    const componentGroupStepNumber = `${previousComponentGroup.index + 3}`;
    const selectedVariationsForPreviousComponentGroup = components.map(component => this.findSelectedVariantByComponentNameAndComponentGroup(component.name, previousComponentGroup.name));
    const getOriginalKeysAndComponentNames = selectedVariationsForPreviousComponentGroup.map(el => {
      const componentName = el && this.findComponentById(el.component_id).name;
      return {
        originalKey: el && el.original_key,
        componentName: componentName,
      }
    });

    const mapToAdobeComponentStructure = {
      Engines: "Engine Model",
      Transmissions: "Transmission",
      Ext_trim_levels: "Exterior Trim",
      Headlights: "Headlights",
      Cabs: "Cab Model",
      Mirrors: "Mirrors",
      Colors: "Cab Color",
      Chassis: "Axle Type",
      Chassi_type: "Chassis Type",
      Seats: "Seats",
      Dashboard: "Dashboard",
      Int_trim_levels: "Interior Trim"
    }
    
    let dataLayerObject = {};
    let previousSelectedComponentGroup = `${previousComponentGroup.name.replaceAll("_", " ")} Selected`;

    getOriginalKeysAndComponentNames.forEach((singleKeyNamePair) => {
      dataLayerObject[mapToAdobeComponentStructure[singleKeyNamePair.componentName]] = singleKeyNamePair.originalKey;
      
      if(singleKeyNamePair.componentName === "Engines") {
        dataLayerObject["Engine Type"] = singleKeyNamePair.originalKey.includes("_lng") ? "lng" : singleKeyNamePair.originalKey.includes("_cng") ? "cng" : singleKeyNamePair.originalKey.includes("_euro5") ? "euro5" : singleKeyNamePair.originalKey.includes("_euro3") ? "euro3" : singleKeyNamePair.originalKey.includes("electric") ? "electric" : "euro6"
      }
    });

    this.sendDataToAdobeDataLayer(
      "applicationInteraction",
      componentGroupStepNumber,
      previousComponentGroup.name.replaceAll("_", " "),
      previousSelectedComponentGroup,
      dataLayerObject
    );
  },


  
  async getApplicationDataForAllMarkets() {
    const response = await axios
      .get(
        this._sectionsApi,
        {
          headers: {
            "Content-type": "application/json",
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId" : this._applicationId
          },
        }
      )
    return response.data.data
  },

  getEmblemBadgesAndGlobetrotterSigns() {
    const HiddenOptionsComponents = this.getComponentGroups().find((componentGroup) => componentGroup.name === "Hidden_options").components;
    HiddenOptionsComponents.forEach((hiddenComponent) => {
      hiddenComponent.available_variations.forEach((availableVar) => {
        availableVar.dependencies.forEach((dep) => {
          this.mSelectedVariations.forEach((selectedVar) => {
            if(selectedVar === dep.depends_on_variation_id) {
              !this.mSelectedVariations.includes(availableVar.id) && this.mSelectedVariations.push(availableVar.id);
            }
          });
        });
      });
    });
  },

  async getRender(request) {
    if(request) {
      return axios.post(`${this._API_ONE_RODMANAGER}/image-provider/get-render`, request, {
        headers: {
          'X-Header-AppId': this._APP_ID_ROD,
          'X-Header-ApiKey': this._API_KEY_ROD,
          'Content-Type': 'application/json'
        }, 
      }).then((response) => {
          if (!response.status === 200) {
            return Promise.reject(new Error(`GetRender request failed: ${response.statusText}`));
          }
          return response.data;
        });
    }
  },

  async checkStatus(request) {
    if(request) {
      return axios.post(`${this._API_ONE_RODMANAGER}/image-provider/check-status`, request, { 
        headers: {
          'X-Header-AppId': this._APP_ID_ROD,
          'X-Header-ApiKey': this._API_KEY_ROD,
          'Content-Type': 'application/json'
        }, 
      }).then((response) => {
          if (!response.status === 200) {
            return Promise.reject(new Error(`GetStatus request failed: ${response.statusText}`));
          }
          return response.data;
        });
    }
  },
    
  sendDataToSalesForce(data, requestedFrom, locale, configurationLink, configImageUrl, callback) {
    const now = new Date();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth()+1;
    const currentYear = now.getFullYear();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentSecond = now.getSeconds();
    const market = locale.split("-")[1];
    const currentProductsID = this._productId;

    if(data) {
      const payload = {
        email: data.email,
        firstName: data.name || data.firstname,
        lastName: data.lastname,
        company: data.company,
        city: data.city, //data.chosen_dealer ? data.chosen_dealer.city : "",
        // country: data.country, //data.chosen_dealer ? data.chosen_dealer.countryname : "",
        postalCode: data.zip, //data.chosen_dealer ? data.chosen_dealer.postcode : "",
        // role: data.role,
        mobilePhone: data.phone,
        contactRequest: "Sales",
        webSource: `https://www.volvotrucks.${market}/${locale}/tools/truck-builder.html#/${locale}`,
        leadSource: "Website (Truck Builder)",
        // corpCommunicationsConsent: requestedFrom === "ContactForm" ? data.pronounTitle : data.privacyCheckbox, 
        marketingConsent: data.marketingCheckbox,
        marketSiteLanguage: `${locale}`,
        // contactDealerSegment: data.salesforceSegment,
        gatedContentURL: `https://www.volvotrucks.${market}`,
        dealer: data.chosen_dealer ? `${data.chosen_dealer.organizationid}${data.chosen_dealer.ctdiid}` : "",
        eventTime : `${currentHour}:${currentMinute}:${currentSecond}`,
        eventDate : `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${currentDate.toString().padStart(2, '0')}`,
        eventName : "",
        formName : requestedFrom === "ContactForm" ? `Truck Builder Contact Sales (${locale})` : `Truck Builder Configuration (${locale})`,
        preferredMeansCommunication: "Email",
        customerMessage: data.message,
        model: this.mLoadedData.product_info.name.replaceAll(" ", "_").toLowerCase(),
        transmission: this.findSelectedVariantByComponentNameAndComponentGroup("Transmissions", "Engine_and_transmission").original_key,
        engine: this.findSelectedVariantByComponentNameAndComponentGroup("Engines", "Engine_and_transmission").original_key,
        cabs: this.findSelectedVariantByComponentNameAndComponentGroup("Cabs", "Exterior").original_key,
        cabColour: this.getSelectedColorFromColorizer().key,
        exteriorTrim: this.findSelectedVariantByComponentNameAndComponentGroup("Ext_trim_levels", "Exterior").original_key,
        headlights: this.findSelectedVariantByComponentNameAndComponentGroup("Headlights", "Exterior").original_key,
        axelConfigurations: this.findSelectedVariantByComponentNameAndComponentGroup("Chassis", "Chassis").original_key,
        interiorTrimLevels: this.findSelectedVariantByComponentNameAndComponentGroup("Int_trim_levels", "Interior").original_key,
        seats: this.findSelectedVariantByComponentNameAndComponentGroup("Seats", "Interior").original_key,
        configuredTruck: configurationLink,
        configuredTruckImage: configImageUrl,
      }

      return axios.post(this._salesforce, payload, 
      {
        headers: {
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        }
      }).then((response) => {
        if (response.data.data.Status === "Success") {
          callback(response.data.data);
          return Promise.reject(new Error(`request failed`));
        }
        callback(response.data.data);
        return response.data;
      })
    }
  },
  
  getSelectedColorFromColorizer() {
    const colorizerProduct = this.findProductByName("TestColorizer");
    const colorGroups = colorizerProduct?.component_groups;
    const allColors = colorGroups?.flatMap(cg => cg?.components.flatMap(ch => ch.available_variations));
    const selectedColor = allColors?.find(color => this.isVariationSelected(color.id))
    return selectedColor;
  },

  variationBelongsToColorizer(variation) {
    const colorizerProduct = this.findProductByName("TestColorizer");
    const colorGroups = colorizerProduct?.component_groups;
    const allColors = colorGroups?.flatMap(cg => cg?.components.flatMap(ch => ch.available_variations));
    return allColors?.some(color => color.id === variation?.id)
  },

  isEverySelectedVariationDefault() {
    const selectedVariations = this.getSelectedVariations();
    const isEverySelectionDefault = selectedVariations.every(variation => variation.is_default || variation.attribute_values?.some(attribute => attribute.value === this.mLoadedData.product_info.description));
    return isEverySelectionDefault;
  }

};
