import classNames from "classnames";
import { customIcon } from "../../consts/custom-icons/customIcon";
import { colorButton } from "../../enum/Button/colorButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { Button } from "../Button/Button";
import { Props } from "./types";
import { useRef, useState } from "react";

export const SearchInput = ({
  onSearch,
  clearValue,
  value,
  placeholder,
  bgColor = "bg-transparent",
  borderBottom,
  searchIcon,
  clearIcon,
  customClassNames,
  searchOpen,
  setSearchOpen,
  isMobile
}: Props) => {
  const searchRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (searchRef.current && searchRef.current.contains(e.target as Node)) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  };

  return (
    <div
      ref={searchRef}
      onClick={handleClick}
      className="relative flex items-center right-6"
    >
      <button
        className={`flex items-center ${ !isMobile ? "w-[269px]" : searchOpen ? "w-[296px]" : "w-10"} ${customClassNames}  overflow-hidden`}
      >
        <div className="flex items-center justify-center w-12 h-12">
          {!searchIcon ? customIcon.Search : searchIcon}
        </div>
        {(!isMobile || searchOpen) && (
          <input
            type="text"
            value={value}
            className="pr-4 text-sm focus:outline-none bg-light-grey-light"
            onBlur={() => setSearchOpen(!searchOpen)}
            placeholder={placeholder}
            onChange={onSearch}
          />
        )}
      </button>
    </div>
  );
};

