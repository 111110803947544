export enum ImageStatus {
  None = "none",
  Fetching = "fetching",
  Queued = "queued",
  Ready = "ready",
  Error = "error",
  Timeout = "timeout",
}

export interface RenderConfiguration {
  productId: number;
  componentGroups: Record<string, Record<string, string>>;
  productName: string | undefined;
}

export enum ImageType {
  Image = "image",
  Cubemap = "cubemap"
}

export enum ImageFormat {
  PNG = "PNG",
  JPEG = "JPEG"
}

export interface ImageStatusInfo {
  isError: boolean;
  error: string;
  urlAssetsStatus: string[];
  urlAssets: string[];
  status: ImageStatus;
  requestId: string;
  hash: string;
}

export interface ImageStatusResponse {
  statusCode: number;
  message: string;
  data: ImageStatusInfo;
}

export interface RenderInfo extends ImageStatusInfo {
  tries: number;
}

export interface Resolution {
  width: number;
  height: number;
}

export const defaultRenderInfo: RenderInfo = {
  isError: false,
  error: "",
  requestId: "",
  hash: "",
  urlAssets: [],
  urlAssetsStatus: [],
  status: ImageStatus.None,
  tries: 0
};

export interface RenderOnDemandState {
  cache: Record<string, RenderInfo>;
  getImages: (hash: string) => RenderInfo;
  setImages: (hash: string, info: RenderInfo) => void;
}

export interface ConfigurationInfo {
  engine: string,
  transmissions: string,
  cab: string,
  color: string,
  trim_levels: string,
  headlights: string,
  chassis: string,
  chassi_type: string
  interior_trim: string,
  seats: string,
}

export interface ImageRequest {
  sequence: string;
  attributes: Record<string, string | undefined>;
  renderVersion: number,
  configuration: RenderConfiguration;
  format?: ImageFormat;
  width: number;
  height: number;
  type: ImageType;
  compressionQuality?: number;
}

export interface CheckStatusRequest {
  requestId: string;
  hash: string;
}