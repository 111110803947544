import { Props } from "./types";
import { Button } from "../Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { colorButton } from "../../enum/Button/colorButton.model";
import { Image } from "../images";

export const SelectedVariants = ({
  image,
  alt,
  category,
  subCategory,
  backgroundColor,
  readMoreLink,
}: Props) => (
  <div className="flex-none">
    <div className="flex flex-col items-center bg-primary py-32">
      <Image
        src={image}
        alt={alt}
        height="160px"
        width="160px"
        objectFit="contain"
        backgroundColor={backgroundColor} 
      />
    </div>
    <p className="text-xl uppercase text-blue-light pt-7 pb-3">{category}</p>
    <div className="flex justify-between">
      <p className="text-lg capitalize">{subCategory}</p>
      <Button
        customClassNames="flex self-start pt-1.5"
        variant={VarianButtonEnum.DEFAULT}
        icon={IconModelEnum.INFO}
        bgColor={colorButton.WHITE}
        onClickEvent={() => window.open(readMoreLink, "_blank")}
      />
    </div>
  </div>
);
