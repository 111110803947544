import React, { useEffect, useState, useContext } from "react";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import useRenderConfig from "../../utils/useRenderConfig";
import useRenderOnDemand from "../../utils/useRenderOnDemand";
import {
  ImageRequest,
  ImageFormat,
  ImageType,
} from "../../utils/useRenderOnDemand.types";
import { PanoramicViewTpl } from "./panoramic.tpl";
import DataContainer from "../../services/DataContainer";
import { MarketSettings, RodSettings } from "../../enum/enums";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useParams } from "react-router-dom";

interface Props {
  isMediumDuty: boolean;
  environment: string;
}

const PanoramicViewROD = ({isMediumDuty, environment}: Props) => {
  const [isDesktop, setIsDesktop] = useState(true);

  const {modelName} = useParams();
  const {modelNameFromUrl} = useParams();

  const steeringwheel_position = DataContainer.sectionKey(
    MarketSettings.MARKET_SETTINGS,
    MarketSettings.STEERINGWHEEL_POSITION
  );

  const pitch = -20;
  const yaw = 170;

  const renderConfig = useRenderConfig();

  const renderSpec: ImageRequest = {
    sequence: isMediumDuty ? `interior_panoramic_${
      renderConfig.productName
    }_${steeringwheel_position.toUpperCase()}` : "panoramic",
    attributes: {
      steeringwheel_position: steeringwheel_position,
      stage: environment,
    },
    configuration: renderConfig,
    renderVersion: parseInt(DataContainer.sectionKey(RodSettings.ROD_SETTINGS, `${modelName ? modelName : modelNameFromUrl}_renderversion`)),
    format: ImageFormat.JPEG,
    height: 3072,
    width: 6144,
    type: ImageType.Cubemap,
    compressionQuality: 90,
  };

  const imageInfo = useRenderOnDemand(renderSpec);

  const baseImageUrl = imageInfo.urlAssets[0] || "";

  useEffect(() => {
    if (window.innerWidth < 840) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, [window.innerWidth]);

  // Lower resolution of imageURL on non-desktop screens
  const imageUrl = isDesktop ? baseImageUrl : baseImageUrl ? `${baseImageUrl}?format=JPG&width=4096&height=2048` : "";

  return imageInfo.error ? (
    <ErrorMessage />
  ) : (
    <PanoramicViewTpl imageUrl={imageUrl} pitch={pitch} yaw={yaw} />
  );
};

export default PanoramicViewROD;
