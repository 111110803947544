import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Props } from "./types";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { Logo } from "../../components/Logo/Logo";
import { Typography } from "../../components/typography";
import { DisclaimerSection } from "../../components/Disclaimer-Section/DisclaimerSection";
import { ModelItem } from "../../components/Model-Item/ModelItem";
import { Loader } from "../../components/Loader/Loader";
import DataContainer from "../../services/DataContainer";
import { MainSettings, MainTextsSettings, DisclaimerTexts, MarketSettings } from "../../enum/enums";

export const Model = ({ title }: Props) => {
  const { data, currentLocale, setActiveComponent, setActiveComponentGroups } = useContext(DataContainerContext);
  const navigate = useNavigate();
  const { urlTitle } = useParams();
  const [dataProducts, setDataProducts] = useState([]);

  const locale = currentLocale?.locale.split("-")[0];
  const market = currentLocale?.locale.split("-")[1];

  const listDisclaimerMenu = [
    {
      title: DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_BUTTON),
      link:  DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.PRIVACY_URL)
    },
    {
      title:  DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_BUTTON),
      link:   DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.COOKIES_URL)
    },
  ];
  
  const segmentSettings = DataContainer.sectionKey(MainSettings.SEGMENT_SETTINGS)?.key_values;
  const mainSegments = DataContainer.sectionKey(MainSettings.MAIN_SEGMENTS)?.key_values;

  useEffect(() => {
    if(data.length && segmentSettings && mainSegments) {
      const titleSegments = urlTitle?.replaceAll("_", " ").toLowerCase();      
      const filteredProducts = segmentSettings[`${MainSettings.SHOW_PRODUCTS}${Object.keys(mainSegments).find(key => (mainSegments[key].toLowerCase() === titleSegments))}`];      
      const productsIdsByOrder = filteredProducts && filteredProducts.replaceAll(" ", "").split(",");
      if (productsIdsByOrder) {        
        const products = data.filter((prod: any) => productsIdsByOrder.includes(prod.id.toString()));        
        const associativeIds: any = {};
        productsIdsByOrder.forEach((val: any, i: number) => {
          associativeIds[val] = i;
        });
        products.sort((a: any, b: any) => (associativeIds[a.id] - associativeIds[b.id]));
        setDataProducts(products);
      }
    }
  }, [data, segmentSettings, mainSegments]);

  return (
    <>
      {!data.length ? (
        <Loader text={DataContainer.sectionKey(MainTextsSettings.MAIN_TEXTS, MainTextsSettings.LOADING_TEXT )} />
      ) : (
        <div className="flex flex-col 2md:flex-row">
          <div className="relative 2md:w-1/2 2md:h-screen pt-0">
            <div className="flex flex-col justify-between h-full pb-5 2md:min-h-[500px]">
              <Logo
                paddingLeftAndRight="p-4 2md:px-10 border-b 2md:border-b-0 border-primary"
                paddingTopAndBottom="2md:pt-14"
              />
              <Typography
                tag="h4"
                className="py-14 text-center text-3xl 2md:text-3xl2 text-black-medium"
              >
                {title}
              </Typography>
              <div className="hidden-vtg 2md:!visible 2md:!flex">
                <DisclaimerSection
                  links={listDisclaimerMenu}
                  positionOfTitle="center"
                  positionOfContent="center"
                  positionOfLinks="center"
                  textTransform={false}
                />
              </div>
            </div>
          </div>
          <div className="2md:w-1/2 h-screen bg-primary overflow-x-hidden overflow-y-scroll">
            {dataProducts.map((model: any) => (
              <ModelItem
                onClickEvent={() => {     
                  if(DataContainer.mLoadedData) {
                    if (DataContainer.getActualModelName() !== model.product_info.description.split("_")[0]) {
                      DataContainer.resetData();
                    }
                  }
                  setActiveComponent({name: "", group_id: 0, index: 0 });
                  setActiveComponentGroups({ name: "", id: 0, index: 0 });
                  navigate(`/${currentLocale.locale}/configurator/${model.product_info.description}`),
                  DataContainer.logEvent("page_entered", "configurator", {product: ""});
                }}
                key={model.id}
                image={model?.preview_image}
                title={model?.product_info?.name || model.name}
                alt={model.name}
              />
            ))}
            <div className="flex 2md:hidden">
              <DisclaimerSection
                links={listDisclaimerMenu}
                positionOfTitle="center"
                positionOfContent="center"
                positionOfLinks="center"
                textTransform={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
