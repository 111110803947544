import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./lib/pannellum.scss";
import pannellum from "./lib/pannellum";

export interface HotspotProps {
  id?: string;
  x: number;
  y: number;
  className?: string;
  initFunc: (container: HTMLDivElement) => void;
  onClick: (evt: React.PointerEvent<HTMLDivElement>) => void;
}

interface PannellumHotspot {
  id: string;
  yaw: number;
  pitch: number;
  createTooltipFunc: (container: HTMLDivElement) => void;
  clickHandlerFunc: (evt: React.PointerEvent<HTMLDivElement>) => void;
  cssClass?: string;
}

export interface PannellumProps {
  id?: string;
  image: string;
  type?: "equirectangular" | "cubemap" | "multires";
  className?: string;
  pitch?: number;
  yaw?: number;
  haov?: number;
  vaov?: number;
  hfov?: number;
  minHfov?: number;
  maxHfov?: number;
  minPitch?: number;
  maxPitch?: number;
  minYaw?: number;
  maxYaw?: number;
  mouseZoom?: boolean;
  showZoomCtrl?: boolean;
  showFullscreenCtrl?: boolean;
  draggable?: boolean;
  showControls?: boolean;
  autoLoad?: boolean;
  onError?: () => void;
  onLoadStart?: () => void;
  onLoadProgress?: (progress: number) => void;
  onLoadFinished?: () => void;
  onImageChange?: () => void;
  children?: React.ReactNode;
}

const getRandomId = () => Math.random().toString(36).substring(2, 9);

const Pannellum = forwardRef<any, PannellumProps>(
  (
    {
      id = "pannellum",
      children,
      className = "",
      image,
      type = "equirectangular",
      pitch = 0,
      yaw = 0,
      haov = 360,
      vaov = 180,
      hfov = 120,
      minHfov = 70,
      maxHfov = 130,
      minPitch = -90,
      maxPitch = 90,
      minYaw = -180,
      maxYaw = 180,
      mouseZoom = true,
      showZoomCtrl = false,
      showFullscreenCtrl = true,
      draggable = true,
      showControls = true,
      autoLoad = true,
      onError,
      onLoadStart,
      onLoadProgress,
      onLoadFinished
    },
    ref
  ) => {
    const hotSpots: PannellumHotspot[] = [];

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const { initFunc, onClick, ...hotspotProps } = element.props as HotspotProps;
      if (initFunc !== undefined && onClick !== undefined) {
        const newHotspot: PannellumHotspot = {
          yaw: hotspotProps.x,
          pitch: hotspotProps.y,
          id: hotspotProps.id ?? getRandomId(),
          clickHandlerFunc: onClick,
          createTooltipFunc: initFunc,
          cssClass: hotspotProps.className
        };

        hotSpots.push(newHotspot);
      }
    });

    const config = {
      type,
      pitch,
      yaw,
      haov,
      vaov,
      hfov,
      minHfov,
      maxHfov,
      minPitch,
      minYaw,
      maxPitch,
      maxYaw,
      mouseZoom,
      showZoomCtrl,
      showFullscreenCtrl,
      draggable,
      showControls,
      hotSpots,
      scenes: {}
    };

    const [currentImage, setCurrentImage] = useState<string>();

    const containerRef = useRef<HTMLDivElement>(null);
    const viewer = useRef<any>(null);

    useImperativeHandle(ref, () => viewer.current);

    useEffect(() => {
      viewer.current = pannellum.viewer(id, config);

      if (onLoadFinished) viewer.current?.on("load", onLoadFinished);
      if (onError) viewer.current?.on("error", onError);
      if (onLoadProgress) viewer.current?.on("progress", onLoadProgress);

      return () => {
        viewer.current?.off("load", onLoadFinished);
        viewer.current?.off("error", onError);
        viewer.current?.off("progress", onLoadProgress);
        viewer.current?.destroy();
      };
    }, []);

    useEffect(() => {
      if (image && image != viewer.current?.getScene()) {
        setCurrentImage(image);
        if (!viewer.current?.hasScene(image)) {
          viewer.current?.addScene(image, {
            ...config,
            panorama: image
          });
        }
        onLoadStart?.();
        viewer.current?.loadScene(
          image,
          viewer.current.getPitch(),
          viewer.current.getYaw(),
          viewer.current.getHfov()
        );
      }
    }, [image, currentImage]);

    useEffect(() => {
      viewer.current?.setPitch(pitch, 1000);
    }, [pitch]);

    useEffect(() => {
      viewer.current?.setYaw(yaw, 1000);
    }, [yaw]);

    useEffect(() => {
      viewer.current?.setHfovBounds([minHfov, maxHfov]);
    }, [maxHfov, minHfov]);

    useEffect(() => {
      viewer.current?.setPitchBounds([minPitch, maxPitch]);
      viewer.current?.setYawBounds([minYaw, maxYaw]);
    }, [minPitch, maxPitch, minYaw, maxYaw]);

    return (
      <div ref={containerRef} id={id} className={className}>
        {children}
      </div>
    );
  }
);

Pannellum.displayName = "Pannellum";
export default Pannellum;
