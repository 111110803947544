type objectList = {
  [key: string]: object,
};

export const customIcon: objectList = {
  Arrow: (
    <svg
      fill="#182972"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="14px"
    >
      <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z" />
    </svg>
  ),
  Search: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3967 19.0017L13.0455 13.6506C12.6183 14.0146 12.1269 14.2963 11.5714 14.4957C11.016 14.6951 10.4577 14.7948 9.89654 14.7948C8.52813 14.7948 7.36994 14.3211 6.42196 13.3737C5.47399 12.4263 5 11.2684 5 9.89996C5 8.53155 5.47342 7.37307 6.42025 6.42453C7.36709 5.47598 8.52471 5.00114 9.89312 5C11.2615 4.99886 12.4203 5.47285 13.3694 6.42196C14.3185 7.37108 14.7931 8.52956 14.7931 9.8974C14.7931 10.491 14.688 11.0656 14.4778 11.621C14.2675 12.1765 13.9912 12.6516 13.6488 13.0464L19 18.3967L18.3967 19.0017ZM9.89739 13.9394C11.0311 13.9394 11.9882 13.5491 12.7687 12.7687C13.5491 11.9882 13.9394 11.0308 13.9394 9.89654C13.9394 8.76228 13.5491 7.80519 12.7687 7.02527C11.9882 6.24536 11.0311 5.85512 9.89739 5.85455C8.7637 5.85398 7.80632 6.24422 7.02527 7.02527C6.24422 7.80633 5.85397 8.76341 5.85454 9.89654C5.85511 11.0297 6.24536 11.9868 7.02527 12.7678C7.80518 13.5489 8.76227 13.9391 9.89654 13.9385" fill="#1E1F22"/>
    </svg>
  ),
  ArrowRightBold: (
    <svg
      width="20"
      height="20"
      className="svg-inline--fa fa-arrow-right fa-w-14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="arrow-right"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
    >
      <path
        fill="#f5f5f5"
        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
      />
    </svg>
  ),
  Done: (
    <svg
      fill="#1251B5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="15px"
      height="15px"
    >
      <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
    </svg>
  ),
};
