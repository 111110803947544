import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { DataContainerProvider } from "./common/context/DataContainerProvider";

setTimeout(() => {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <DataContainerProvider>
          {/* <RodProvider> */}
          <App />
          {/* </RodProvider> */}
        </DataContainerProvider>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById(process.env.REACT_APP_ID as string)
  );
}, 1500);
