import React, { useEffect } from "react";
import DataContainer from "../services/DataContainer";

const useShowExterior360 = () => {
  //Currently we will only show the 360 Toggle Button for Default Variations
  //But in the future this may change, and that's why this hook exists, in case additional logic needs to be added.
  //! If there's no default variation for a component, then we choose the first(0) variation as default.
  //! Variations can also be selected depending on Dependencies.
  //TODO: Make sure about 2 cases above to either have a default selection for every component or handle the [0] selection.
  //TODO: Make sure if in the first selection we have variations that are selected by the Dependency they have on a Default.
  if (DataContainer.isEverySelectedVariationDefault()) {
    return true;
  } else {
    return false;
  }
};

export default useShowExterior360;