import {Props, VariantItemProps} from "./types";
import classNames from "classnames";
import {Typography} from "../typography";
import {customIcon} from "../../consts/custom-icons/customIcon";
import {useContext, useEffect, useRef, useState} from "react";
import SwiperCore, {Mousewheel, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import dataContainer from "../../services/DataContainer";
import {DataContainerContext} from "../../common/context/DataContainerProvider";
import {AdobeCampaignService} from "../../services/adobe/AdobeCampaign";
import {AdobeEventType} from "../../enum/adobe/adobeEventType";
import {ConfiguratorOptions} from "../../enum/enums";
import { useParams } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const responsiveBreakpoints = {
  640: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 2,
  },
  1200: {
    slidesPerView: 4.5
  }
};

export const VariantItemSwiper = ({updateCurrentView}: Props) => {

  const {activeComponent, activeComponentGroups} = useContext(DataContainerContext);
  const [triggerVariation, setTriggerVariation] = useState(false);
  const [toggelNavigationPrevElement, setToggelNavigationPrevElement] = useState(false);
  const [toggelNavigationNextElement, setToggelNavigationNextElement] = useState(true);
  const [variantState, setVariantState] = useState<[]>();
  const [variantKey, setVariantKey] = useState<any>();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const modelName = dataContainer.getActualModelName();
  const adobeService = new AdobeCampaignService();
  const prevVariantName = adobeService.usePrevious(variantKey);

  useEffect(() => {
    if (activeComponent.name) {
      const variations = dataContainer.findComponentVariations(
        activeComponent.group_id
      );
      const currentSegment = localStorage.getItem("segment");
      const sectionKey =
        `${modelName}_${currentSegment}_DISABLED_OPTIONS`.toLocaleLowerCase();
      const variationsToSkip = dataContainer
        .sectionKey("Segment.Settings", sectionKey)
        .split(",");
      const trimmedVariationsToSkip = variationsToSkip.map((s: string) => s.trim());

      let filteredVariations = variations.filter((variation: any) => {
        if (trimmedVariationsToSkip.includes(variation.original_key)) {
          return false;
        } else if (variation.dependencies?.length > 0) {
          const dependencyVariationsComponents = dataContainer.filterSameComponentVariations(variation.dependencies);
          if(variation.dependencies?.length > 1 && dependencyVariationsComponents.length > 1) {
            const chosenVariationBasedOnDependencyOfParentVariation = variation.dependencies.filter((dependency: any) => dataContainer.isVariationSelected(dependency.depends_on_variation_id));
            return chosenVariationBasedOnDependencyOfParentVariation.length > 1 ? true : false;
          }
          const chosenVariationBasedOnDependencyOfParentVariation =
            variation.dependencies.find((dependency: any) =>
              dataContainer.isVariationSelected(dependency.depends_on_variation_id));
          return chosenVariationBasedOnDependencyOfParentVariation ? true : false;
        }
        return true;
      });
      const currentComp = dataContainer.findComponentByName(
        activeComponent.name
      );
      const chassisComp = dataContainer.findComponentByName("Chassis");
      if (currentComp && currentComp.name === "Chassi_type") {
        const tempVariationChassiTypeIds = dataContainer
          .findComponentVariations(currentComp.id)
          .map((itm: any) => {
            return itm.id;
          });
        const tempVariationChassisIds = dataContainer
          .findComponentVariations(chassisComp.id)
          .map((itm: any) => {
            return itm.id;
          });
        const variationsToIgnore = dataContainer.filterChassiTypeByEngineType(
          tempVariationChassiTypeIds,
          tempVariationChassisIds
        );
        filteredVariations = filteredVariations.filter((v: any) => {
          return !variationsToIgnore.includes(v.id);
        });

        if(variationsToIgnore && variationsToIgnore.length == 1) {
          replaceDisabledSelectedVariations(currentComp, variationsToIgnore);
        }
      }

      if (currentComp && currentComp.name === "Chassis") {
        const tempVariationChassisIds = dataContainer
          .findComponentVariations(chassisComp.id)
          .map((itm: any) => {
            return itm.id;
          });
        const variationsToIgnore = dataContainer.filterChassisByEngineType(
          tempVariationChassisIds
        );
        filteredVariations = filteredVariations.filter((v: any) => {
          return !variationsToIgnore.includes(v.id);
        });
      }

      setVariantState(filteredVariations);
      setToggelNavigationPrevElement(false);
    }
  }, [activeComponent.name]);

  const replaceDisabledSelectedVariations = (currentComp: any, variationsToReplace: number[]) => {
    const selectedVariations: number[] = dataContainer.mSelectedVariations;
    const chassiTypeVariationToRemove: number = variationsToReplace[0];
    const indexOfChassiTypeToRemove: number = selectedVariations.indexOf(chassiTypeVariationToRemove);

    if(selectedVariations.includes(chassiTypeVariationToRemove)) {
      if (indexOfChassiTypeToRemove !== -1) {
        selectedVariations.splice(indexOfChassiTypeToRemove, 1);
      }
    }
    
    const chassiVariationToRemove: number | any = selectedVariations.find((id: any) => dataContainer.findVariationBy(id).dependencies?.find((dep: any) => dep?.depends_on_variation_id == chassiTypeVariationToRemove));
    const indexOfChassiToRemove: number = selectedVariations.indexOf(chassiVariationToRemove);

    if(selectedVariations.includes(chassiVariationToRemove)) {
      if (indexOfChassiToRemove !== -1) {
        selectedVariations.splice(indexOfChassiToRemove, 1);
      }
    }
    const defaultChassiTypeVariation = currentComp.available_variations.find((variation: any) => variation.is_default);
    const defaultChassisVariation = dataContainer.findComponentByName("Chassis")?.available_variations.find((variation: any) => variation.dependencies.find((dep: any) => dep.depends_on_variation_id == defaultChassiTypeVariation.id));
    selectedVariations.push(defaultChassiTypeVariation.id);
    selectedVariations.push(defaultChassisVariation.id);
  };
  
  useEffect(() => {
    setToggelNavigationNextElement(!!(variantState && variantState.length > 4));
    
    updateCurrentView(!triggerVariation);
  }, [variantState]);

  useEffect(() => {
    dataContainer.mLoadedData && adobeService.sendAnalytics(AdobeEventType.VARIANT, {
      data: prevVariantName
    });
  }, [variantKey]);


  const triggerVariationClick = (variant: VariantItemProps) => {
    updateCurrentView(!triggerVariation);
    const attribute = dataContainer.findAttributeFromAssets(variant.key);
    if(attribute) dataContainer.markAttributeAsSelected(attribute);
    !dataContainer.isVariationSelected(variant.id) &&
        dataContainer.toggleVariationSelection(variant.id);
  };

  const extractTranslationForVariation = (section:string, key:string, defaultStr = "") => {
    const translation = dataContainer.sectionKey(section, key);
    return translation !== key ? translation : defaultStr ? defaultStr : "";
  };

  const camelize = (str: string, makeFirstUpper: boolean) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return "";
      return index === 0 && !makeFirstUpper ? match.toLowerCase() : match.toUpperCase();
    });
  };

  const translateVariation = (variation: any) => {
    const component = dataContainer.findComponentById(variation.component_id);
    let sectionKey = `Options.${component.name}`;    

    const componentGroup = dataContainer.findComponentGroupByComponentId(variation.component_id);
    if (["interior", "exterior"].includes(componentGroup.name.toLowerCase())){
      if (["Ext_trim_levels", "Int_trim_levels"].includes(component.name)) {
        sectionKey = `${`Options.${componentGroup.name}${camelize(component.name.split("_").slice(1).join("_").replaceAll("_"," "), true)}`}`;
      }
    }
    let variationTranslation = dataContainer.sectionKey(
      sectionKey,`${variation.original_key}_title`);
    if(variationTranslation.includes(variation.original_key)){
      variationTranslation = dataContainer.sectionKey(sectionKey,`${variation.original_key}`);
    }
    return variationTranslation;
  };
  
  return (
    <Swiper
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
        disabledClass: "disabled_swiper_button"
      }}
      pagination={false}
      mousewheel={true}
      freeMode={true}
      breakpoints={ responsiveBreakpoints }
      className="variant-item--swiper border-t relative flex items-center max-h-32"
      tag="ul"
    >
      {variantState?.map((variant: VariantItemProps) => (
        !variant.assets.some((asset: any) => asset.attribute_values.some((attr: any) => attr.value === "Disabled")) ?
          <SwiperSlide tag="li" key={variant.id}  onClick={() => {          
            setVariantKey(variant.key);
            triggerVariationClick(variant);
          }} >
            <div className={classNames("flex justify-between hover:cursor-pointer items-center py-0 !pl-0 !pr-10 h-32 border-r border-gray-light", variant.className,
              {"justify-between px-10" : activeComponent.name === ConfiguratorOptions.COLORS},
              {"!px-0 !pr-12 !pl-0" : activeComponent.group_id === 1458},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.EXTERIOR_TRIM},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.HEADLIGHTS},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.CHASSI_TYPE},
              {"!pr-10 !pl-0" : activeComponent.name === ConfiguratorOptions.SEATS}

            )}
            >
              <div className="flex items-center h-full w-full">
                <div style={{backgroundImage : "url(" + variant?.assets[0]?.preview_image_url + ")"}}
                  className={classNames("w-auto rounded-full h-full", variant.className,
                    {"background-none" : (!dataContainer?.isVariationSelected(variant.id) ||
                          activeComponent.name !== ConfiguratorOptions.COLORS)} )}
                >

                  <img
                    src={variant?.assets[0]?.preview_image_url}
                    alt={variant.key}
                    className={classNames("w-full h-full sm:w-24 break-words object-contain object-left border-white", variant.className,
                      {"rounded-full w-20 h-20" : activeComponent.name === ConfiguratorOptions.COLORS},
                      {"!w-28 !pl-0 object-cover" : activeComponent.name === ConfiguratorOptions.EXTERIOR_TRIM},
                      {"!w-24 !px-0 object-cover" : activeComponent.name === ConfiguratorOptions.HEADLIGHTS},
                      {"!w-10 !px-0" : activeComponent.name === ConfiguratorOptions.AXLE_CONFIGURATIONS},
                      {"!w-full !object-contain" : activeComponent.name === ConfiguratorOptions.SEATS},
                      {"!w-28 sm:!w-28" : activeComponent.name === ConfiguratorOptions.INTERIOR}

                    )}
                  />
                </div>
                <>
                  <div
                    className={classNames(
                      {
                        "text-blue": dataContainer?.isVariationSelected(variant.id),
                        "text-dark-grey": !dataContainer?.isVariationSelected(variant.id),
                      },
                      "flex flex-col justify-start w-1/2 uppercase pl-4"
                    )}
                  >
                    <div className="pt-1">
                      <Typography tag="h5" className="text-xl break-words">
                        {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}_title`)}
                      </Typography>
                    </div>
                    {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}`) === "" && 
                    extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}_title`) === "" &&
                    <div className="pt-1">
                      <Typography tag="h5" className="text-xl break-words">
                        {translateVariation(variant)}
                      </Typography>
                    </div>}
                    <div className="py-1">
                      <Typography tag="h5" className="text-xl break-word">
                        {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}`)}
                      </Typography>
                    </div>
                  </div>
                </>
              </div>

              <div className="w-3">
                {dataContainer?.isVariationSelected(variant.id) && customIcon.Done}
              </div>
            </div>
          </SwiperSlide>
          : 
          <SwiperSlide 
            tag="li" 
            key={variant.id} 
          >
            <div className={classNames("flex justify-between hover:cursor-pointer bg bg-gray-200 items-center py-0 !pl-0 !pr-10 h-32 border-r border-gray-light", variant.className,
              {"justify-between px-10" : activeComponent.name === ConfiguratorOptions.COLORS},
              {"!px-0 !pr-12 !pl-0" : activeComponent.group_id === 1458},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.EXTERIOR_TRIM},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.HEADLIGHTS},
              {"!pl-0 !pr-10" : activeComponent.name === ConfiguratorOptions.CHASSI_TYPE},
              {"!pr-10 !pl-0" : activeComponent.name === ConfiguratorOptions.SEATS}

            )}
            >
              <div className="flex items-center h-full w-full">
                <div 
                  style={{backgroundImage : "url(" + variant?.assets[0]?.preview_image_url + ")"}}
                  className={
                    classNames(
                      "w-auto rounded-full h-full", 
                      variant.className,
                      {"background-none" : (!dataContainer?.isVariationSelected(variant.id) ||
                          activeComponent.name !== ConfiguratorOptions.COLORS)
                      }
                    )
                  }
                >
                  <img
                    src={variant?.assets[0]?.preview_image_url}
                    alt={variant.key}
                    className={classNames("w-full h-full sm:w-24 break-words object-contain object-left border-white", variant.className,
                      {"rounded-full w-20 h-20" : activeComponent.name === ConfiguratorOptions.COLORS},
                      {"!w-28 !pl-0 object-cover" : activeComponent.name === ConfiguratorOptions.EXTERIOR_TRIM},
                      {"!w-24 !px-0 object-cover" : activeComponent.name === ConfiguratorOptions.HEADLIGHTS},
                      {"!w-10 !px-0" : activeComponent.name === ConfiguratorOptions.AXLE_CONFIGURATIONS},
                      {"!w-full !object-contain" : activeComponent.name === ConfiguratorOptions.SEATS},
                      {"!w-28 sm:!w-28" : activeComponent.name === ConfiguratorOptions.INTERIOR}

                    )}
                  />
                </div>
                <div
                  className="flex flex-col justify-start w-full uppercase pl-4 text-dark-grey"
                >
                  <div className="pt-1">
                    <Typography tag="h5" className="text-xl break-words">
                      {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}_title`)}
                    </Typography>
                  </div>
                  {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}`) === "" && 
                  extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}_title`) === "" &&
                    <div className="pt-1">
                      <Typography tag="h5" className="text-xl break-words">
                        {translateVariation(variant)}
                      </Typography>
                    </div>
                  }
                  <div className="py-1">
                    <Typography tag="h5" className="text-xl break-word">
                      {extractTranslationForVariation(`Options.${activeComponent.name}`,`${variant.original_key}`)}
                    </Typography>
                  </div>
                  <div className="py-1">
                    <Typography tag="h6" className="text-xs break-words">
                      {extractTranslationForVariation(`Options.${activeComponent.name}`, "disabled_variation")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
      ))}
      <div
        ref={navigationPrevRef}
        className={classNames(
          `variant-item-swiper variant-item-swiper--prev z-20
           left-0 absolute top-0 hidden sm:flex items-center h-full w-24
           justify-center cursor-pointer outline-none`,
        )}
      >
        <svg fill="none" height="17" viewBox="0 0 25 17" width="25" xmlns="http://www.w3.org/2000/svg">
          <path d="M25 8.5H1M1 8.5L8.44615 1M1 8.5L8.44615 16" stroke="#1E1F22" strokeLinejoin="bevel"/>
        </svg>
      </div>
      <div
        ref={navigationNextRef}
        className={classNames(
          `variant-item-swiper variant-item-swiper--next z-20 hidden sm:flex
                  right-0 absolute top-0 items-center h-full w-24
                  justify-center cursor-pointer outline-none`,
        )}
      >
        <svg fill="none" height="17" viewBox="0 0 25 17" width="25" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 8.5H24M24 8.5L16.5538 1M24 8.5L16.5538 16" stroke="#1E1F22" strokeLinejoin="bevel"></path>
        </svg>
      </div>
    </Swiper>
  );
};
