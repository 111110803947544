import wrap from "../../utils/useCyclicalWrap";

const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(max, value));

const smoothStep = (x: number) => {
  return -2 * Math.pow(x, 3) + 3 * Math.pow(x, 2);
};

class TurntableHandler {
  duration = 1;
  requestFrame = 0;
  position = 0;
  lastFrame = -1;
  target = 0;
  lastVel = 0;
  startPos = 0;
  startTime = 0;
  images: HTMLImageElement[] = [];
  canvas: HTMLCanvasElement | null = null;
  isDragging = false;

  constructor(images: string[], time = 0, duration = 1) {
    this.setImages(images);
    this.position = time;
    this.duration = duration;
    this.target = time;
  }

  setTime(value: number, immediate?: boolean) {
    if (immediate) {
      this.position = value;
      this.target = value;
    } else {
      this.startTime = Date.now();
      this.startPos = this.position;
      this.target = value;
      this.startAnimation();
    }
  }
  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.render();
  }
  updateFrame() {
    // Goes backwards through the image sequence
    const newFrame = Math.round(this.position * (this.images.length - 1));
    if (newFrame !== this.lastFrame) {
      if (this.images[newFrame]?.complete) {
        this.drawImage(this.images?.[newFrame]);
        this.lastFrame = newFrame;
      } 
    }
  }
  animate() {
    let delta = this.target - this.startPos; // Difference between target and current value;
    const invDelta =
      this.target < this.startPos
        ? 1 - this.startPos + this.target
        : -this.startPos - (1 - this.target); // Distance required to go around the seam

    if (Math.abs(invDelta) < Math.abs(delta)) {
      // It's faster to loop around the seam
      delta = invDelta;
    }
    const dist = Math.abs(this.target - this.position);

    const t = clamp((Date.now() - this.startTime) / (this.duration * 1000), 0, 1);
    const targetPos = this.startPos + delta * smoothStep(t);
    this.position = wrap(0, 1, targetPos);

    this.updateFrame();

    if (dist <= 0.0005) {
      this.requestFrame = 0;
      return; // Animation finished
    }

    this.requestFrame = requestAnimationFrame(() => this.animate());
  }
  stopAnimation() {
    if (this.requestFrame) {
      cancelAnimationFrame(this.requestFrame);
    }
  }
  startAnimation() {
    this.stopAnimation();
    this.requestFrame = requestAnimationFrame(() => this.animate());
  }
  
  // drawImage(image: HTMLImageElement) {
  //   const context = this.canvas?.getContext("2d");
  //   if (this.canvas && context && image) {
  //     // This logic calculates an image fit similar to "cover"
  //     const ratio = image.width / image.height;
  //     const canvasWidth = this.canvas.width;
  //     const canvasHeight = this.canvas.height;

  //     let newWidth = canvasWidth;
  //     let newHeight = newWidth ;

  //     if (newHeight < canvasHeight) {
  //       newHeight = canvasHeight;
  //       newWidth = newHeight * ratio;
  //     }

  //     const xOffset = newWidth > canvasWidth ? (canvasWidth - newWidth) / 1.5 : 0;
  //     const yOffset = newHeight > canvasHeight ? (canvasHeight - newHeight) / 1.5 : 0;

  //     context.clearRect(0, 0, canvasWidth, canvasHeight);
  //     context.drawImage(image, xOffset, yOffset, newWidth, newHeight);
  //   }
  // }

  drawImage(image: HTMLImageElement) {
    const context = this.canvas?.getContext("2d");
    if (!this.canvas || !context || !image) return;
  
    // Get canvas and image dimensions
    const imgRatio = image.width / image.height;
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;
    const canvasRatio = canvasWidth / canvasHeight;
  
    let newWidth, newHeight;
  
    if (imgRatio > canvasRatio) {
      // Image is wider than canvas → scale by height
      newHeight = canvasHeight;
      newWidth = canvasHeight * imgRatio;
    } else {
      // Image is taller than canvas → scale by width
      newWidth = canvasWidth;
      newHeight = canvasWidth / imgRatio;
    }
  
    // Center the image (crop edges if needed)
    const xOffset = (canvasWidth - newWidth) / 2;
    const yOffset = (canvasHeight - newHeight) / 1.5;
  
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    context.drawImage(image, xOffset, yOffset, newWidth, newHeight);
  }

  render() {
    const currentFrame = Math.round(this.position * (this.images.length - 1));
    const image = this.images[currentFrame];
    if (image?.complete) {
      this.drawImage(image);
    }
  }
  setImages(images: string[]) {
    if (images.length === this.images.length && !images.some((v, i) => this.images[i].src !== v)) {
      // Images are the same
      return;
    }
    this.images.length = images.length;
    images.forEach((img, i) => {
      if (this.images[i]?.src === img) return;

      const image = (this.images[i] = new Image());
      const onLoad = () => {
        if (!this.images[i]) return;


        const currentFrame = Math.round(this.position * (images.length - 1));
        if (i === currentFrame) {
          this.drawImage(image);
        }
      };
      image.onload = onLoad;
      image.src = img;
      if (image.complete) {
        onLoad();
      }
    });
  }
  onPointerUp() {
    this.isDragging = false;
    this.lastVel = 0;
  }
  onPointerDown() {
    this.isDragging = true;
  }
  onPointerMove(evt: { movementX: number }) {
    if (this.isDragging && this.canvas) {
      const dx = evt.movementX;
      const width = this.canvas.getBoundingClientRect().width;

      const velocity = -dx / width;

      this.lastVel = velocity;

      this.position = wrap(0, 1, this.position + velocity);

      this.updateFrame();
    }
  }
}

export default TurntableHandler;
