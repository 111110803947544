import {Props, VariantItemProps} from "./types";
import classNames from "classnames";
import { useState, useEffect, useRef, useContext, useMemo } from "react";
import SwiperCore, { Navigation, Pagination, Mousewheel} from "swiper";
import dataContainer from "../../services/DataContainer";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { ConfiguratorOptions } from "../../enum/enums";
import { useParams } from "react-router-dom";
import { Button } from "../Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { textButton } from "../../enum/Button/textButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { SearchInput } from "../Search-Input/SearchInput";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

interface ActiveColorGroup {
  name: string;
  id: number;
  index: number;
}
interface ActiveColorHue {
  name: string;
  id: number;
  group_id: number;
  index: number;
}

interface ColorObject {
  chosenColor: string;
  colorComponent: string;
  colorComponentGroup: string
}

export const VariantItemSwiperColors = ({updateCurrentView, expandedColorizer, toggleColorizer, isMobile}: Props) => {
  const {
    activeComponent,
    data,
    defaultVariantColor,
    isColorComingFromURL } = useContext(DataContainerContext);
  const [variantState, setVariantState] = useState<any[] | never>([]);
  const [triggerVariation, setTriggerVariation] = useState(false);
  const [variantKey, setVariantKey] = useState<any>();
  const modelName = dataContainer.getActualModelName();
  const [preventFromSelectingDefault, setPreventFromSelectingDefault] = useState(true);
  const [colorObj, setColorObj] = useState<ColorObject>({chosenColor: "", colorComponent: "", colorComponentGroup: ""});
  const [searchTerm, setSearchTerm] = useState("");
  const [activeColorGroup, setActiveColorGroup] = useState<ActiveColorGroup>({ name: "", id: 0, index: 0 });
  const [activeColorHue, setActiveColorHue] = useState<ActiveColorHue>({ name: "", id:0, group_id: 0, index: 0 });
  const [searchOpen, setSearchOpen] = useState(false);
  const [colorsArray, setColorsArray] = useState<any[] | undefined>([]);
  const colorizerProduct = data.find((prd: { id: number; }) => prd.id == 181);

  const colorGroups = colorizerProduct?.component_groups; 
  const colorHues = colorGroups?.find((cg: any) => cg.id === activeColorGroup.id)?.components;
  const allColors = useMemo(() => colorGroups?.flatMap((cg: any) => cg?.components.flatMap((ch: any) => ch.available_variations)), [colorizerProduct]);

  const handleColorGroupClick = (colorGroup: ActiveColorGroup) => {
    setActiveColorGroup(colorGroup);
    setSearchTerm("");
  };

  const handleColorHueClick = (colorHue: ActiveColorHue) => {
    setActiveColorHue(colorHue);
    setSearchTerm("");
  };

  useEffect(() => {
    if (colorGroups) {
      const defaultColorGroup = colorGroups.find((cg: any) => cg?.components.find((ch: any) => ch.available_variations.some((variation: { id: any; }) => dataContainer.isVariationSelected(variation.id))));
      const defaultColorHue = dataContainer.findComponentById(dataContainer.getSelectedColorFromColorizer().component_id);
      if (activeColorGroup.name === "") {
        setActiveColorGroup({
          name: defaultColorGroup?.name,
          id: defaultColorGroup?.id,
          index: 0,
        });
      }
      if(colorHues) {
        if (activeColorHue.name === "") {
          setActiveColorHue({
            name: defaultColorHue?.name,
            id: defaultColorHue?.id,
            group_id: defaultColorHue?.group_id,
            index: 0,
          });
        } else if (activeColorHue.name === "" || (activeColorHue.group_id !== activeColorGroup.id)) {
          setActiveColorHue({
            name: colorGroups.find((cg: any) => cg.name === activeColorGroup.name)?.components[0]?.name,
            id: colorGroups.find((cg: any) => cg.name === activeColorGroup.name)?.components[0]?.id,
            group_id: colorGroups.find((cg: any) => cg.name === activeColorGroup.name)?.components[0]?.group_id,
            index: 0,
          });
        }
        setColorsArray(colorHues.find((a:any) => a.id === activeColorHue.id)?.available_variations);
      }
    }

    if(searchTerm) {
      setColorsArray(allColors?.filter((item: any) => item.key.toLowerCase().includes(searchTerm)));
    }
  },[activeColorGroup, colorGroups, activeColorHue, colorHues, searchTerm]);

  useEffect(() => {
    if (colorsArray?.length) {
      const currentSegment = localStorage.getItem("segment");
      const sectionKey =
        `${modelName}_${currentSegment}_DISABLED_OPTIONS`.toLocaleLowerCase();
      const variationsToSkip = dataContainer
        .sectionKey("Segment.Settings", sectionKey)
        .split(",").map((s: string) => s.trim());

      const filteredVariations = colorsArray.filter((variation: any) => {
        if(variationsToSkip.includes(variation.original_key)){
          return false;
        }
        else if(variation.dependencies?.length > 0){
          const chosenVariationBasedOnDependencyOfParentVariation = variation.dependencies
            .find((dependency: any) => dataContainer.isVariationSelected(dependency.depends_on_variation_id));
          return chosenVariationBasedOnDependencyOfParentVariation ? true : false;
        }
        return true;
      });

      const containsDefaultSelection = allColors?.some((color: VariantItemProps) => color.attribute_values.length && color.attribute_values.find((attribute) => attribute.value === modelName));
      if (!containsDefaultSelection && !isColorComingFromURL) {
        dataContainer.toggleVariationSelection(allColors[0]?.id);
      } 
      else if (isColorComingFromURL && preventFromSelectingDefault) {
        const isColorValid = allColors.find((color: VariantItemProps) => color.key === defaultVariantColor);
        if (!isColorValid) {
          dataContainer.toggleVariationSelection(allColors[0]?.id);
        }
        setPreventFromSelectingDefault(false);
      }

      setVariantState(filteredVariations);
    }
  }, [colorsArray]);

  
  const triggerVariationClick = (variant: VariantItemProps) => {
    !dataContainer.isVariationSelected(variant.id) && dataContainer.toggleVariationSelection(variant.id);
    updateCurrentView(!triggerVariation);
  };
  
  useEffect(() => {
    if(allColors.length) {
      const colorVariation = allColors.find((variation: VariantItemProps) => dataContainer.isVariationSelected(variation.id));
      const colorComponent = dataContainer.findComponentById(colorVariation?.component_id);
      const colorComponentGroup = dataContainer.findComponentGroupByID(colorComponent?.group_id);
      setColorObj({
        chosenColor: colorVariation?.key,
        colorComponent: colorComponent?.name,
        colorComponentGroup: colorComponentGroup?.name
      });
    }
  }, [variantKey]);

  return (
    <>
      <div 
        className={classNames(
          "relative transition-all transform border-t duration-300 ease-in-out bottom-0",
          {"h-32 max-h-32": !isMobile && !expandedColorizer},
          {"h-[280px] max-h-[280px]": !isMobile && expandedColorizer}, //"h-[372px] max-h-[372px]" Figma height of expanded Colorizer
          {"h-56 max-h-56": !expandedColorizer && isMobile},  //"h-32 max-h-32" if we want to make collapsed colorizer shorter for mobile devices
          {"max-h-[100vh]" : (expandedColorizer && isMobile)}
        )}
      >
        <div className="h-[52px] flex justify-between items-center">
          <div className="relative left-6">
            <Button
              variant={VarianButtonEnum.ICONBUTTON}
              onClickEvent={() => toggleColorizer(!expandedColorizer)}
              text={!isMobile ? (!expandedColorizer ? "show more" : "show less") : ""}
              customClassNames="bg-light-grey-light hover:bg-light-grey-hover text-xs h-10 w-10 sm:w-32"
              textColor={textButton.DARK_GREY_LIGHT}
              icon={!expandedColorizer ? IconModelEnum.ShowMoreButton : IconModelEnum.ShowLessButton}
            />
          </div>
          <div className={classNames(
            "text-center relative md:left-[70px] flex flex-row py-3 gap-1 text-xs",
            {"flex-col": isMobile},
            {"hidden": (isMobile && searchOpen)}
          )}>
            <div>
              <span className="uppercase">{colorObj?.colorComponentGroup} </span>
              <span className="uppercase text-font-vonum-bold">{colorObj?.chosenColor} </span>
            </div>
          </div>
          <SearchInput 
            value={searchTerm}
            onSearch={(e) => setSearchTerm(e.target.value)}
            customClassNames="transition-all transform duration-300 ease-in-out bg-light-grey-light h-10 rounded-full"
            isMobile={isMobile}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        </div>
        <div className={`transition-all transform duration-300 ease-in-out border-t ${!expandedColorizer && "!h-0 overflow-hidden"} border-t-gray-lighter h-[180px] md:h-28 flex flex-col justify-center items-center`}>
          <div className="flex justify-center gap-8 h-full w-1/3">
            {colorGroups?.map((cg: any, idx: number) => (
              <div
                key={idx}
                className={classNames(
                  {"border-b transform duration-100 ease-in border-black": activeColorGroup?.name === cg.name},
                  "max-h-3/4 self-center gap-2 flex justify-between transform duration-200 ease-in")}
              >
                <Button 
                  variant={VarianButtonEnum.TEXTBUTTON}
                  text={cg.name}
                  customClassNames="!p-0 h-5"
                  onClickEvent={() => handleColorGroupClick({name: cg.name, id: cg.id, index: idx})}
                />
              </div>
            ))
            }
          </div>
          {(isMobile && expandedColorizer) && <div className="bg-gray-lighter h-0.5 w-full"></div>}
          <div className="flex flex-wrap justify-center gap-x-10 md:gap-x-8 gap-y-5 h-full w-full py-6 ">
            {colorHues?.map((ch: any, idx: number) => (
              <div
                key={idx}
                className={classNames(
                  {"border-b border-black transform duration-100 ease-in": activeColorHue?.name === ch.name},
                  "max-h-3/4 self-center flex justify-between transform duration-200 ease-in")}
              >
                <Button 
                  variant={VarianButtonEnum.TEXTBUTTON}
                  text={ch.name}
                  customClassNames="!p-0 h-5"
                  onClickEvent={() => handleColorHueClick({name: ch.name, id: ch.id, group_id: ch.group_id, index: idx})}
                />
              </div>
            ))
            }
          </div>
        </div>
        <div 
          className={classNames(
            "border border-t overflow-y-auto overflow-x-hidden h-full border-gray-lighter transition-all transform duration-300 ease-in-out",
            {"max-h-[73px]": !expandedColorizer && !isMobile},
            {"max-h-28": expandedColorizer && !isMobile}, //"max-h-52"
            {"max-h-[160px]": !expandedColorizer && isMobile}, //"max-h-[80px]" for shorter collapsed colorizer(mobile)
            {"max-h-[65vh]": expandedColorizer && isMobile},
          )}
        >
          <div dir="rtl" className="relative overflow-x-hidden bg-white" id="color-scrollbar">
            <div dir="ltr" className="grid xl:grid-cols-30 lg:grid-cols-20 md:grid-cols-12 sm:grid-cols-8 grid-cols-8">
              {variantState?.map((variant: VariantItemProps, idx: number) => (
                <div 
                  className="h-12 w-auto border-r border-b flex justify-center items-center py-0 border-gray-lighter" 
                  title={variant.key}
                  id={"color-variation"} 
                  key={idx} 
                  onClick={() => {
                    setVariantKey(variant.key);
                    triggerVariationClick(variant);
                  }}>
                  <div className={classNames("flex w-8 h-8 hover:cursor-pointer items-center py-0 justify-center", variant.className)}>
                    <div style={dataContainer?.isVariationSelected(variant.id) ? { backgroundColor: `#${variant.original_key.slice(2)}` } : {backgroundColor: "transparent"}}
                      className={classNames("rounded-full p-px", variant.className)}>
                      <img
                        style={{
                          backgroundColor: `#${variant.original_key.slice(2)}`,
                        }}
                        src={variant?.assets[0]?.preview_image_url}
                        alt={variant.key}
                        className={"object-contain border-2 border-white rounded-full"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="h-7 absolute bottom-0 left-0 w-full pointer-events-none bg-gradient-to-b from-transparent to-white hover:cursor-pointer"></div>
    </>
  );
};
